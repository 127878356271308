.wrapper-career {
    .green-text {
        color: #329879 !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 12px;
    }

    .main-bannernew .main-contentss .faqsheads h3 {
        font-size: 54px;
        max-width: 753px;
    }

}

.main-bannernew {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;
    height: 320px;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        object-fit: cover;
    }

    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 753px;
        width: 100%;
        margin: 0 auto;

        .main-logo {
            margin-bottom: 22px;
        }

        h4 {
            color: #fff;
            text-align: center;
            font-size: 75px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            margin-bottom: 29px;
        }

        p {
            color: #d4d4d4;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 120%;
            margin-bottom: 40px;
        }

        .twice-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 14px;
            width: 100%;

            a {
                max-width: 200px;
                width: 100%;
                padding: 20px;
                white-space: nowrap;
            }
        }
    }

    .main-contentss {
        // max-width: 1066px;
        width: 100%;
        margin: 0 auto;

        .insuranceheads {
            max-width: 760px;
            width: 100%;
            margin: 0 auto;

            h3 {
                color: #FFF;
                font-size: 55px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                text-transform: uppercase;
            }

            span {
                display: flex;
                align-items: center;
                gap: 5px;
                color: #61FF00;
                font-size: 34px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                text-transform: uppercase;
            }
        }

        .faqsheads {
            h3 {
                color: #FFF;
                font-size: 46px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: 0.92px;
                margin-bottom: 22px;
            }

            p {
                color: #9a9a9a;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                max-width: 654px;
                width: 100%;
            }
        }
    }
}


.education-section {
    padding-top: 41px;
    padding-bottom: 81px;

    .main-heading {
        margin-bottom: 25px;

        h6 {
            color: #0B0B0B;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
        }
    }

    .bottom-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        .single-card {
            cursor: pointer;

            .main-img {
                position: relative;

                .play-icon {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                }
            }

            h6 {
                color: #0B0B0B;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                text-transform: uppercase;
                margin-top: 10px;
            }
        }
    }
}


@media (max-width:600px) {
    .education-section .bottom-content {
        grid-template-columns: 1fr;
        gap: 30px;
    }
    .wrapper-career .main-bannernew .main-contentss .faqsheads h3{
        font-size: 22px;
    }
}



