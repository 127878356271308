.kycmain {
    padding: 45px 15px 50px 15px;
    min-height: calc(100vh - 140px);

    .kycinner {
        background: var(--Box-Background-Color, #ECF5F9);
        border-radius: 4px;
        overflow: hidden;
        max-width: 568px;
        width: 100%;
        margin: 0 auto;

        .innertop {
            padding: 24px 20px;
            border-bottom: 1px solid var(--Stroke, #C0CBCF);

            .topheading {
                color: var(--Primary-Text-Color, #02171D);
                font-family: "f37";
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }

            .failed{
                color: var(--Red, #FC4F52) !important;
            }
        }

        .innerbody {
            padding: 20px;

            .kycpara {
                color: #77868B;
                font-family: "hregular";
                font-size: 16px;
                font-style: normal;
                line-height: normal;
                margin-bottom: 30px;

                .supportlink{
                    color: #77868B;
                    font-family: "hregular";
                    font-size: 16px;
                    font-style: normal;
                    line-height: normal;
                    text-decoration: underline !important;
                }
            }

            .kycbutton {
                border-radius: 6px;
                background: #329879;
                display: flex;
                padding: 19px 22px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFF;
                font-family: "hmedium";
                font-size: 14px;
                font-style: normal;
                line-height: 100%;
                border: none;
                width: 100%;
            }
        }
    }
}