.mainnavbardashboard {
  padding: 0px 0px 0px 0px !important;
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 999;
  position: relative;

  .navbar-collapse {
    position: relative;
  }

  .navbar {
    background: transparent;
    padding: 0px;
    margin: 0px;
    border: none;
    width: 100%;
    align-items: center;

    .navbar-toggler {
      box-shadow: none !important;
      border: none !important;
      padding: 0;
      margin: 0;

      .navbar-toggler-icon {
        background: url("../../../assets/menu.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 0px;
        margin: 0px;
        box-shadow: none !important;
        border: none !important;
        padding: 0;
        width: 1em !important;
        height: 1em !important;
      }
    }

    .navbar-brand {
      margin: 0px;
      padding: 0px;
      margin-right: 33px;
    }

    .navbar-nav {
      margin: 0px;
      padding: 0px;
      gap: 30px;
      position: absolute;
      left: 0px;
      bottom: -14px;

      .active {
        color: #329879;
        font-weight: 700;
        border-bottom: 2px solid #329879;
      }

      a {
        margin: 0px;
        padding: 0px;
        color: var(--Secondary-Text-Color, #77868B);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        padding-bottom: 26px;
        border-bottom: 2px solid transparent;
      }
    }

    .navbtns {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 10px;

      .conneccctbbtbn {
        border-radius: 6px;
        background: var(--Primary-Green, #329879);
        border: none;
        padding: 13px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        outline: none;
        color: var(--Button-Text, #E2E9EC);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        transition: 0.3s linear;

        &:hover {
          border-radius: 4px;
          background: #1E7E61;
        }

        &:active {
          border-radius: 4px;
          background: #1E7E61;
          box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.50);
        }
      }

      .inviteleader-btn {
        border-radius: 12px;
        background: #F8F8F8 !important;
        border: none !important;
        color: #000 !important;



      }

      .btn .arrow {
        transition: transform 0.3s;
      }

      .btn .arrow.open {
        transform: rotate(180deg);
      }

      .dropdown {
        .btn .arrow {
          transition: transform 0.3s;
        }

        .btn .arrow.open {
          transform: rotate(180deg);
        }

        .dropdown-toggle::after {
          display: none !important;
        }

        .btn {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          border: none;
          padding: 6px 16px 6px 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          color: #000;
          outline: none;



          .profileimg {
            border-radius: 300px;
            background: #fff;
            display: flex;
            width: 30px;
            height: 30px;
            padding: 1px;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            object-position: center;

            .profileimginner {
              border-radius: 300px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .profilebtntexts {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .profilebtnhead {
              color: #02171D;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-family: "hregular";
              line-height: 120%;
              letter-spacing: 0.24px;
              text-transform: uppercase;
            }

            .profilebtnpara {
              color: var(--Secondary-Text-Color, #77868B);
              font-size: 10px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 120%;
              letter-spacing: 0.2px;
              text-transform: uppercase;
            }
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          border: none;
          width: 255px;
          padding: 10px;

          .dropdownmenuinner {
            .rankmain {
              border-radius: 4px;
              background: #329879;
              display: flex;
              padding: 8px;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 2px;
              margin-bottom: 5px;

              .currrankapara {
                color: #E2E9EC;
                font-size: 10px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 120%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
              }

              .rankmainhead {
                color: var(--Button-Text, #E2E9EC);
                font-size: 14px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 100%;
              }

              .rankwhitepara {
                color: #fff;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
              }
            }

            .mainprofiledrop {
              border-radius: 4px;
              background: var(--Text-Field-Background, #D1DBDF);
              display: flex;
              padding: 8px;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              cursor: pointer;

              .profiledroppara {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 100%;
              }


            }

            .disconnectdrop {
              border-radius: 4px;
              background: var(--Text-Field-Background, #D1DBDF);
              display: flex;
              padding: 8px;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              cursor: pointer;

              .disconnectdroppara {
                color: #FC4F52;
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
              }


            }
          }
        }
      }
    }
  }
}

.mainnavbar {
  border-bottom: 1px solid #C1CBCE;
  padding: 10px 0px;

  .customnavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mobile-menu {
  background-color: #E2E9EC;
  width: 100% !important;
  border: none !important;
  max-width: 100% !important;

  .offcanvas-header {
    border-bottom: 1px solid var(--Stroke, #C0CBCF);
    background: var(--Background-Color, #E2E9EC);
    backdrop-filter: blur(15px);
    padding: 22px 20px;

    .btn-close {
      background: url(../../../assets/closesidebar.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      box-shadow: none !important;
      outline: none !important;
      padding: 0px;
      margin: 0px;
      opacity: unset;
    }
  }

  .offcanvas-body {
    padding: 0;

    .navbtns {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 19px 20px;
      gap: 10px;

      .conneccctbbtbn {
        border-radius: 6px;
        background: var(--Primary-Green, #329879);
        border: none;
        padding: 13px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        outline: none;
        color: var(--Button-Text, #E2E9EC);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        transition: 0.3s linear;
        width: 100%;

        &:hover {
          border-radius: 4px;
          background: #1E7E61;
        }

        &:active {
          border-radius: 4px;
          background: #1E7E61;
          box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.50);
        }
      }

      .inviteleader-btn {
        border-radius: 12px;
        background: #F8F8F8 !important;
        border: none !important;
        color: #000 !important;



      }

      .btn .arrow {
        transition: transform 0.3s;
      }

      .btn .arrow.open {
        transform: rotate(180deg);
      }

      .dropdown {
        width: 100%;
        .btn .arrow {
          transition: transform 0.3s;
          position: absolute;
          top: 8px;
          right: 18px;
        }

        .btn .arrow.open {
          transform: rotate(180deg);
        }

        .dropdown-toggle::after {
          display: none !important;
        }

        .btn {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          border: none;
          padding: 6px 16px 6px 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 12px;
          color: #000;
          outline: none;
          width: 100%;
          position: relative;



          .profileimg {
            border-radius: 300px;
            background: #fff;
            display: flex;
            width: 30px;
            height: 30px;
            padding: 1px;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            object-position: center;

            .profileimginner {
              border-radius: 300px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .profilebtntexts {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .profilebtnhead {
              color: #02171D;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-family: "hregular";
              line-height: 120%;
              letter-spacing: 0.24px;
              text-transform: uppercase;
            }

            .profilebtnpara {
              color: var(--Secondary-Text-Color, #77868B);
              font-size: 10px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 120%;
              letter-spacing: 0.2px;
              text-transform: uppercase;
            }
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          border: none;
          width: 100%;
          padding: 10px;

          .dropdownmenuinner {
            .rankmain {
              border-radius: 4px;
              background: #329879;
              display: flex;
              padding: 8px;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 2px;
              margin-bottom: 5px;

              .currrankapara {
                color: #E2E9EC;
                font-size: 10px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 120%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
              }

              .rankmainhead {
                color: var(--Button-Text, #E2E9EC);
                font-size: 14px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 100%;
              }

              .rankwhitepara {
                color: #fff;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
              }
            }

            .mainprofiledrop {
              border-radius: 4px;
              background: var(--Text-Field-Background, #D1DBDF);
              display: flex;
              padding: 8px;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              cursor: pointer;

              .profiledroppara {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-family: "hmedium";
                line-height: 100%;
              }


            }

            .disconnectdrop {
              border-radius: 4px;
              background: var(--Text-Field-Background, #D1DBDF);
              display: flex;
              padding: 8px;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              cursor: pointer;

              .disconnectdroppara {
                color: #FC4F52;
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 100%;
              }


            }
          }
        }
      }
    }

    .nav {
      flex-direction: column;

      a {
        border-bottom: 1px solid var(--Stroke, #C0CBCF);
        background: var(--Background-Color, #E2E9EC);
        display: flex;
        height: 60px;
        padding: 19px 20px;
        align-items: center;
        color: var(--Primary-Green, #329879);
        font-family: "hmedium";
        font-size: 18px;
        font-style: normal;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .mainnavbar {
    padding: 10px 0px !important;
  }

  .mainnavbardashboard .navbar .navbar-nav {
    position: unset;
  }

  .mainnavbardashboard {
    height: unset;
  }

  .mainnavbardashboard .navbar {
    padding: 10px 0px;
  }

  .mainnavbardashboard .navbar .navbar-nav a {
    text-align: center;
  }

  .mainnavbardashboard .navbar .navbtns .dropdown .btn {
    width: 100%;
  }

  .mainnavbardashboard .navbar .navbtns .dropdown .dropdown-menu {
    width: 100%;
  }

  .mainnavbardashboard .navbar .navbtns {
    flex-direction: column;
  }

  .mainnavbardashboard .navbar .navbtns .conneccctbbtbn {
    width: 100%;
  }

  .mainnavbardashboard .navbar .navbtns .dropdown {
    width: 100%;
  }

  .mainnavbardashboard .navbar .navbtns .dropdown .btn {
    width: 100%;
  }

  .metamask-none-mobile {
    display: none !important;
  }

  .walletconnectmodal .modal-dialog .modal-content .modal-body .walletmain .walletinner {
    width: 100% !important;
  }

  .mainnavbardashboard .navbar .navbar-nav .active {
    border-bottom: 0px;
    color: #4CEAD7;
  }

  .mainnavbardashboard .navbar .navbtns .conneccctbbtbn {
    box-shadow: 0px -10px 0px 0px rgba(255, 255, 255, 0.04) inset;
  }

  .mainnavbardashboard .navbar .navbtns .dropdown .btn {
    box-shadow: 0px -10px 0px 0px rgba(255, 255, 255, 0.04) inset;
  }
}


.premium-modal {
  .modal-dialog {
    max-width: 554px !important;
  }

  .premium-reward-box {
    position: relative;
    z-index: 9;
    padding: 20px;

    .reward-modal-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }

    .main-heading {
      margin-bottom: 32px;

      p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        text-transform: uppercase;
        margin-bottom: 13px;
        text-align: center;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .premium-feature {
      .heading {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.36px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .feature-box {
        border-radius: 12px;
        border: 1px solid #212121;
        background: rgba(44, 44, 44, 0.20);
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 36px;
        width: 100%;

        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.14px;
          padding: 13px 0;
          border-bottom: 1px solid #212121;
          width: 100%;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    .premium-requirement {
      .heading {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.36px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .points-box {
        border-radius: 12px;
        border: 1px solid #212121;
        background: rgba(44, 44, 44, 0.20);
        backdrop-filter: blur(4.5px);
        padding: 7px 20px;

        &:last-child {
          margin-top: 6px;
        }

        .inside-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #212121;
          padding: 13px 0;

          p {
            color: #B0B0B0;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.32px;
            text-transform: uppercase;
          }

          h6 {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.16px;
            text-transform: uppercase;

            &.special-text {
              color: #329879;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.2px;
              text-transform: uppercase;
            }
          }
        }
      }
    }


  }

  .modalbtns {
    margin-top: 20px;
  }
}

@media (max-width:600px) {
  .premium-modal .premium-reward-box {
    padding: 15px;
  }

  .premium-modal .premium-reward-box .twice-parent {
    flex-direction: column;
    gap: 30px;
  }

  .premium-modal .premium-reward-box .premium-feature .heading {
    font-size: 16px;
  }

  .premium-modal .premium-reward-box .premium-requirement .heading {
    font-size: 16px;
  }

  .premium-modal .premium-reward-box .premium-requirement .points-box .inside-text p {
    font-size: 14px;
  }

  .premium-modal .premium-reward-box .premium-requirement .points-box .inside-text h6.special-text {
    font-size: 18px;
  }
}


.reward-custom-box {
  border-radius: 12px;
  border: 1px solid #EEE;
  background: var(--Black, #000);
  box-shadow: 0px -9px 0px 0px rgba(255, 255, 255, 0.12) inset;
  padding: 20px;
  margin-bottom: 15px;

  .inner-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;

    &:last-child {
      padding-bottom: 0;
    }


    h6 {
      color: #B0B0B0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0.28px;
      text-transform: uppercase;
    }

    p {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.16px;
      text-transform: uppercase;
    }
  }

  .inner-text-border {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 15px;
  }
}


.insuffient-repo {
  border-radius: 6px;
  border: 1px solid rgba(254, 0, 0, 0.05);
  background: rgba(254, 0, 0, 0.15);
  backdrop-filter: blur(17px);
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  margin-bottom: 40px;

  .text {
    h6 {
      color: #F44;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.16px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    p {
      color: #F44;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: 0.28px;
    }
  }
}


.education-btn {
  border-radius: 12px;
  border: 1px solid #329879;
  background: #111;
  padding: 6px 15px 6px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}