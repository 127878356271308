.termsmain {
    padding-top: 13px;
    padding-bottom: 83px;
    min-height: calc(100vh - 140px);
    padding-left: 15px;
    padding-right: 15px;

    .termsinner {
        border-radius: 4px;
        background: var(--Box-Background-Color, #ECF5F9);
        padding-bottom: 20px;
        max-width: 560px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;

        .innertop {
            padding: 24px 20px;
            border-bottom: 1px solid var(--Stroke, #C0CBCF);
            background: var(--Box-Background-Color, #ECF5F9);

            .tophead {
                color: var(--Primary-Text-Color, #02171D);
                font-family: "f37";
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 15px;
            }

            .toppara {
                color: var(--Primary-Text-Color, #02171D);
                font-family: "hregular";
                font-size: 14px;
                font-style: normal;
                line-height: 120%;
            }
        }

        .innermid {
            padding: 20px 20px 0px;

            .scrollbox {
                padding: 20px;
                background: var(--Text-Field-Background, #D1DBDF);
                height: 385px;
                margin-bottom: 20px;
                border-radius: 4px;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 4px;
                  }
                  
                  &::-webkit-scrollbar-track {
                    background: var(--Stroke, #C0CBCF);
                  }
                   
                  &::-webkit-scrollbar-thumb {
                    background: var(--Primary-Green, #329879);
                  }

                .scrollhead {
                    color: var(--Primary-Text-Color, #02171D);
                    font-family: "hsemibold";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 127%;
                    margin-bottom: 20px;
                }

                .scrollpara {
                    color: var(--Primary-Text-Color, #02171D);
                    font-family: "hregular";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 127%;
                    margin-bottom: 20px;
                }

                ul{
                    list-style-type: disc;
                    margin: 0;
                    margin-left: 20px !important;
                    margin-bottom: 20px !important;
                    li{
                        color: var(--Primary-Text-Color, #02171D);
                        font-family: "hregular";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 127%;
                        margin-bottom: 10px;
                    }
                }

                strong{
                    font-family: 'hsemibold';
                }
            }

            .btndull {
                border-radius: 4px;
                background: var(--Disable-Button-BG, #C8E5E0);
                display: flex;
                padding: 15px 22px;
                justify-content: center;
                align-items: center;
                color: var(--Disable-Button-Text, #8EA7A4);
                font-family: "hmedium";
                font-size: 14px;
                font-style: normal;
                line-height: 100%;
                border: none;
                width: 100%;
            }

            .btngreen {
                border-radius: 4px;
                background: var(--Primary-Green, #329879);
                display: flex;
                padding: 15px 22px;
                justify-content: center;
                align-items: center;
                color: var(--Disable-Button-Text, #E2E9EC);
                font-family: "hmedium";
                font-size: 14px;
                font-style: normal;
                line-height: 100%;
                border: none;
                width: 100%;
            }
        }
    }
}