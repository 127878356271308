.myteam {
  padding: 32px 0px;
  min-height: 100vh;

  .parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    z-index: 0;
    position: relative;
    z-index: 99;

    .left {
      img {
        border-radius: 140px;
        width: 44px;
      }

      h2 {
        color: #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      .innerright {
        position: relative;

        img {
          position: absolute;
          top: 14px;
          right: 20px;
        }

        input {
          border-radius: 10px;
          border: 1px solid #e6e6e6;
          background: #fff;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
          width: 100%;
          max-width: 241px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          padding: 15px 20px;

          &::placeholder {
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }
      }

      .dropbtn {
        .dropdown {
          .dropdown-toggle::after {
            display: none !important;
          }

          .dropdown-toggle {
            max-width: 188px;
            width: 100%;
            border-radius: 12px;
            border: 1px solid #e6e6e6;
            background: #fff;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            padding: 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #000 !important;
            width: 188px !important;
            text-align: start;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: start;
          }

          .dropdown-menu {
            background: #ffffff;
            border: 1px solid #dfe6e9;
            border-radius: 7px;
            padding: 16px 12px 0px;
            width: 100%;
            margin-top: 6px;
          }

          .dropdown-item {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            color: #2d3436;
            border-bottom: 1px solid red !important;
            margin-bottom: 10px;
            padding: 0px;
            padding-bottom: 10px;

            &:last-child {
              border-bottom: none !important;
            }
          }
        }

        /* Genel stil */

        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 24px;
          margin: 10px;
        }

        /* Giriş stil */
        .toggle-switch .toggle-input {
          display: none;
        }

        /* Anahtarın stilinin etrafındaki etiketin stil */
        .toggle-switch .toggle-label {
          position: absolute;
          top: 0;
          left: 0;
          width: 44px;
          height: 24px;
          background-color: #b2bec3;
          border-radius: 16px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        /* Anahtarın yuvarlak kısmının stil */
        .toggle-switch .toggle-label::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          background: #ffffff;
          transition: transform 0.3s;
        }

        /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
        .toggle-switch .toggle-input:checked + .toggle-label {
          background-color: #0010f7;
        }

        .toggle-switch .toggle-input:checked + .toggle-label::before {
          transform: translateX(20px);
        }

        /* Light tema */
        .toggle-switch.light .toggle-label {
          background-color: #bebebe;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label {
          background-color: #9b9b9b;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label::before {
          transform: translateX(6px);
        }

        /* Dark tema */
        .toggle-switch.dark .toggle-label {
          background-color: #4b4b4b;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label {
          background-color: #717171;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label::before {
          transform: translateX(16px);
        }
      }
    }
  }

  .addteammain {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #797977;
    background: #fff;
    box-shadow: 0px -8px 0px 0px rgba(0, 0, 0, 0.06) inset;
    flex-direction: column;
    padding: 50px 30px;

    .addteamimg {
      margin-bottom: 15px;
    }

    .addteamhead {
      color: #000;
      text-align: center;
      margin-bottom: 5px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
    }

    .addteampara {
      color: #84848e;
      margin-bottom: 30px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    .addteambtn {
      border-radius: 15px;
      background: #f8f8f8;
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
      display: flex;
      padding: 15px 26px;
      justify-content: center;
      align-items: center;
      color: #b6b6b6;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      border: none;
    }
  }
}

.newaddmodal {
  .modal-dialog {
    max-width: 490px !important;
  }
}

.addteammembermodal {
  .modal-dialog {
    max-width: 500px;

    .modal-header {
      margin-bottom: 15px;
    }

    .modal-body {
      .addingmain {
        display: flex;
        align-items: center;
        gap: 15px;

        .addingcard {
          border-radius: 17px;
          border: 1px solid #f7f7f7;
          background: #fff;
          display: flex;
          padding: 15px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 450px;
          width: 100%;
          margin: 0 auto;

          .addinghead {
            color: #000;
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
          }

          .addingpara {
            color: #84848e;
            margin-bottom: 22px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
          }
        }
      }

      .addedmodal {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .addedhead {
          color: #000;
          margin-top: 20px;
          margin-bottom: 30px;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }
      }

      .dropdown {
        width: 100%;
        margin-bottom: 18px;
        .btn {
          box-shadow: none !important;
          padding: 0;
          margin: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          border: 1px solid #eee;
          background: #fff;
          padding: 16px 18px;
          width: 100%;
          box-shadow: none !important;
          color: #ababab;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          &::after {
            color: #000;
          }
        }
      }

      .selectdropdown {
        width: 100%;

        .btn {
          background: transparent;
          box-shadow: none !important;
          border: none !important;
          padding: 0;
          margin: 0;
          width: 100%;
          color: #000;

          &::after {
            display: none !important;
          }

          .material-textfield {
            position: relative;

            .arrowimg {
              position: absolute;
              top: 55px;
              right: 12px;
            }

            label {
              text-align: start;
            }
          }
        }

        .dropdown-menu {
          border-radius: 10px;
          border: 1px solid #eee;
          padding: 0px;
          background: #fff;
          width: 100%;
          top: -10px !important;

          .dropitem {
            border-bottom: 1px solid #eee;
            padding: 5px 10px;
          }
        }
      }

      .modalbtns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .whitebtn {
          padding: 18px 25px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}

////////////..........teamdetailScss.........../////
.teamdetail {
  padding: 32px 0px;

  .teamdetailtop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .teamdetailleft {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;

      .teamdetailimg {
        width: 49px;
        height: 49px;
        border-radius: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;

        .innerteamimg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .teamdetailtexts {
        h3 {
          color: #000;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.52px;
          text-transform: uppercase;
        }

        p {
          color: #959595;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          span {
            color: #329879;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.28px;
            text-transform: uppercase;
          }

          .forline {
            border-radius: 4px;
            background: #dfdfdf;
            width: 4px;
            height: 17px;
            display: block;
            margin: 0px 6px;
          }
        }
      }
    }

    .teamdetailright {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 22px;

      button {
        border-radius: 15px;
        border: 1px solid #797977;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        padding: 15px 30px;
        // max-width: 150px;
        width: 100%;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
      }

      .first {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        p {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 24px;
        }

        /* Giriş stil */
        .toggle-switch .toggle-input {
          display: none;
        }

        /* Anahtarın stilinin etrafındaki etiketin stil */
        .toggle-switch .toggle-label {
          position: absolute;
          top: 0;
          left: 0;
          width: 44px;
          height: 24px;
          border-radius: 16px;
          background: #dadada;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        /* Anahtarın yuvarlak kısmının stil */
        .toggle-switch .toggle-label::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          background: #ffffff;
          transition: transform 0.3s;
        }

        /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
        .toggle-switch .toggle-input:checked + .toggle-label {
          background-color: #329879;
        }

        .toggle-switch .toggle-input:checked + .toggle-label::before {
          transform: translateX(20px);
        }

        /* Light tema */
        .toggle-switch.light .toggle-label {
          background-color: #bebebe;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label {
          background-color: #9b9b9b;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label::before {
          transform: translateX(6px);
        }

        /* Dark tema */
        .toggle-switch.dark .toggle-label {
          background-color: #4b4b4b;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label {
          background-color: #717171;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label::before {
          transform: translateX(16px);
        }
      }
    }
  }

  .teamdetailcard {
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    background: #fff;
    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
    padding: 30px;
    width: 100%;
    margin: 25px 0px;

    .teamparent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .innercontent {
        max-width: 312px;
        width: 100%;

        h3 {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        p {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }
      }
    }
  }

  .dashboardboxesmain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    margin-bottom: 20px;

    .innerdashboardbox {
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      background: #fff;
      box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;

      .innerdashboardboxupper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .dashboardboxtexts {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 6px;

          .dashboardparatopbox {
            color: var(--Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
            opacity: 0.6;
          }

          .codepara {
            color: #4cead7;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
          }

          .dashboardheadbox {
            color: var(--Black, #000);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .redtext {
              color: #fe0000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.14px;
            }

            .greentext {
              color: #329879;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.14px;
            }
          }
        }
      }

      .valuemaindiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        position: relative;
        width: 100%;

        .dropdown {
          position: absolute;
          right: 0;
          bottom: 0;

          .btn {
            padding: 0px;
            margin: 0px;
            background: transparent;
            border: none;
            outline: none;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            padding: 0px;
            margin: 0px;
            border-radius: 12px;
            border: 1px solid #e6e6e6;
            background: #fff;
            box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
            width: 208px;

            .teamsdrops {
              .innerteamdrop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 18px;
                border-bottom: 1px solid #797977;

                .teamdroppara {
                  color: #777;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .teamdrophead {
                  color: var(--Black, #000);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }
            }
          }
        }

        .valuemain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .valueline {
            border-radius: 4px;
            background: #dfdfdf;
            width: 3px;
            height: 14px;
            display: block;
          }

          .valuepara {
            color: var(--Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.14px;

            .darktext {
              color: var(--Black, #000);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              letter-spacing: -0.14px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .tabsmain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .searchinputmain {
      position: relative;

      .searchinput {
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        width: 427px;
        padding: 15px 20px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        outline: none;

        &::placeholder {
          color: #666;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }

      .searchinputimg {
        position: absolute;
        top: 15px;
        right: 20px;
      }
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      background: var(--Black, #000) !important;
      box-shadow: 0px -5px 0px 0px rgba(255, 255, 255, 0.2) inset !important;
      color: #fff !important;
    }

    .nav {
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      width: 365px;
      padding: 5px;

      .nav-item {
        width: 50%;

        .nav-link {
          color: var(--Black, #000);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%;
          display: flex;
          padding: 12px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .teamdetail .teamdetailcard .teamparent {
    flex-wrap: wrap;
  }
  .teamdetail .teamdetailcard .teamparent .innercontent {
    width: 49%;
    max-width: unset;
  }
  .teamdetail .dashboardboxesmain {
    grid-template-columns: 1fr;
  }
  .teamdetail .teamdetailtop {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  .teamdetail .tabsmain {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 600px) {
  .addteammembermodal .modal-dialog .modal-body .addingmain {
    flex-direction: column;
  }
  .teamdetail .tabsmain .searchinputmain .searchinput {
    width: 100%;
  }
  .teamdetail .tabsmain .searchinputmain {
    width: 100%;
  }
  .teamdetail .tabsmain .nav {
    width: 100%;
  }
  .teamdetail .teamdetailcard .teamparent .innercontent {
    width: 100%;
  }
}
