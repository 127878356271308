.mainproject {
    padding-top: 32px;
    padding-bottom: 32px;
    min-height: 100vh;
    .parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        z-index: 0;
        position: relative;

        .left {
         
            img{
                border-radius: 140px;
                width: 44px;
            }
            h2 {
                color: #000;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.6px;
                text-transform: uppercase;
            }
        }

        .right {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .innerright {
                position: relative;

                img {
                    position: absolute;
                    top: 14px;
                    right: 20px;
                }

                input {
                    border-radius: 10px;
                    border: 1px solid #E6E6E6;
                    background: #FFF;
                    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                    width: 100%;
                    max-width: 241px;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    padding: 15px 20px;

                    &::placeholder {
                        color: #666;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }
                }
            }

            .dropbtn {

                .dropdown {
                    .dropdown-toggle::after {
                        display: none !important;
                    }

                    .dropdown-toggle {

                        max-width: 188px;
                        width: 100%;
                        border-radius: 12px;
                        border: 1px solid #E6E6E6;
                        background: #FFF;
                        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                        padding: 15px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: #000 !important;
                        width: 188px !important;
                        text-align: start;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        text-align: start;


                    }

                    .dropdown-menu {
                        background: #FFFFFF;
                        border: 1px solid #DFE6E9;
                        border-radius: 7px;
                        padding: 16px 12px 0px;
                        width: 100%;
                        margin-top: 6px;
                    }

                    .dropdown-item {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 16px;
                        display: flex;
                        align-items: flex-end;
                        color: #2D3436;
                        border-bottom: 1px solid #E6E6E6;
                        margin-bottom: 10px;
                        padding: 0px;
                        padding-bottom: 10px;

                        &:last-child {
                            border-bottom: none !important;
                        }
                    }
                }

                /* Genel stil */

                .toggle-switch {
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 24px;
                    margin: 10px;
                }

                /* Giriş stil */
                .toggle-switch .toggle-input {
                    display: none;
                }

                /* Anahtarın stilinin etrafındaki etiketin stil */
                .toggle-switch .toggle-label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 44px;
                    height: 24px;
                    background-color: #B2BEC3;
                    ;
                    border-radius: 16px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                /* Anahtarın yuvarlak kısmının stil */
                .toggle-switch .toggle-label::before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    top: 2px;
                    left: 2px;
                    background: #FFFFFF;
                    transition: transform 0.3s;
                }

                /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
                .toggle-switch .toggle-input:checked+.toggle-label {
                    background-color: #0010F7;

                }

                .toggle-switch .toggle-input:checked+.toggle-label::before {
                    transform: translateX(20px);
                }

                /* Light tema */
                .toggle-switch.light .toggle-label {
                    background-color: #BEBEBE;
                }

                .toggle-switch.light .toggle-input:checked+.toggle-label {
                    background-color: #9B9B9B;
                }

                .toggle-switch.light .toggle-input:checked+.toggle-label::before {
                    transform: translateX(6px);
                }

                /* Dark tema */
                .toggle-switch.dark .toggle-label {
                    background-color: #4B4B4B;
                }

                .toggle-switch.dark .toggle-input:checked+.toggle-label {
                    background-color: #717171;
                }

                .toggle-switch.dark .toggle-input:checked+.toggle-label::before {
                    transform: translateX(16px);
                }

            }
        }
    }

    .main-card {
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        width: 100%;
        background: #FFF;

        .footer-content {
            border-radius: 0px 0px 4px 4px;
            background: var(--Box-Background-Color, #ECF5F9);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 22px;
      
            .left-f {
              h6 {
                color: var(--Secondary-Text-Color, #77868B);
                font-family: "hmedium";
                font-size: 12px;
                font-style: normal;
                line-height: 110%; 
                letter-spacing: 0.1px;
              }
            }
      
            .right-f {
                .pagination {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  }
              .page-link:focus {
                box-shadow: none;
                background-color: unset;
              }
      
              .page-link:hover {
                box-shadow: none;
                background-color: unset;
              }
      
              .page-link {
                color: #869397;
                font-family: "f37";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                border: none;
                outline: none;
                box-shadow: none;
                background: transparent;
                padding: 6px 11px;
      
                &.active {
                  background: #329879;
                  border-radius: 5px;
                  background: #C1EBE2;
                }
              }
            }
          }

        .table-responsive {
            .table{
                margin: 0px;
            }
     
            th {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                padding: 28px 20px;
                vertical-align: middle;
                border: none;
                white-space: nowrap;

                img {
                    margin-left: 8px;
                }
            }

            td {
                white-space: nowrap;
                padding: 18px 20px;
                border-top: 1px solid rgba(3, 2, 41, 0.05);
                vertical-align: middle;

                .parenttbl {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;

                    .left{
                        img{
                            width: 44px;
                            border-radius: 140px;
                            max-width: 44px;
                        }
                    }

                    .right {
                        h6 {
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            letter-spacing: 0.24px;
                            margin-bottom: 0px;
                        }

                        p {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                            margin-top: 4px;
                            margin-bottom: 0px;
                        }
                    }
                }

                .para {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 11px;
                    letter-spacing: 0.28px;
                }

                h6 {
                    color: #000;
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                    letter-spacing: 0.28px;
                }

                p {
                    color: #000;
                    margin-top: 2px;
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0.28px;
                }
            }

        }

    }
}

@media (max-width:786px){
    .mainproject .parent{
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width:600px){
    .mainproject .main-card .table-responsive{
        display: block !important;
    }
    .mainproject .parent .right{
        flex-direction: column;
        width: 100%;
    }
    .mainproject .parent .right .innerright{
        width: 100%;
    }
    .mainproject .parent .right .innerright input{
        width: 100%;
        max-width: 100%;
    }
    .mainproject .parent .right .dropbtn .dropdown .dropdown-toggle{
        width: 100% !important;
        max-width: 100%;
    }
    .mainproject .parent .right .dropbtn{
        width: 100% !important;
        max-width: 100%;
    }
    .mainproject .main-card .footer-content .left-f{
        display: none !important;
    }
    .mainproject .main-card .footer-content{
        justify-content: center;
    }
}