.forgotmain {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 15px;
  padding-top: 152px;

  .redpara {
    width: 100%;
    text-align: start;
    margin-bottom: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }

  .forgotinner {
    border-radius: 4px;
    border: 1px solid #f1f1f1;
    background: #ecf5f9;
    display: flex;
    max-width: 423px;
    width: 100%;
    padding: 32px 20px;
    flex-direction: column;
    align-items: center;

    .emailimg {
      margin-bottom: 31px;
    }

    .forgothead {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 8px;
      text-transform: uppercase;
      text-align: center;
    }

    .widthpara {
      max-width: 300px;
      width: 100%;
    }

    .resendpara {
      color: #5c5c5c;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      margin-top: 15px;
    }

    .forgotpara {
      color: #ababab;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 22px;
      text-align: center;
    }

    .greybtn {
      display: flex;
      padding: 18px 25px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
