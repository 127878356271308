.loginfooter {
    width: 100%;
    border-top: 1px solid var(--Light-Mode-Strock-Color, #C1CBCE);
    backdrop-filter: blur(15px);
    padding: 17px 0px;

    .innerloginfooter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footerpara {
            color: var(--Light-Mode-Secondary-Text-Color, var(--Secondary-Text-Color, #77868B));
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 140%;
        }
    }
}