.viewdetail {
  padding: 60px 0;

  .back-button {
    border-radius: 4px;
    background: #ECF5F9;
    border: none;
    padding: 15px 22px 15px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
    text-transform: uppercase;
    max-width: 140px;
    width: 100%;
    /* 16px */
  }

  .main-heading111 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        position: relative;
        // max-width: 627px;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: var(--Text-Field-Background, #D1DBDF);

        .inputdrop {
          background: transparent;
          background-color: transparent;

          .btn {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: 100%;
            background: transparent;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border: none;
            height: 49px;
            border-radius: 0px;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            border-radius: 4px;
            background: var(--Text-Field-Background, #D1DBDF);
            padding: 0px;
            margin: 0px;
            max-height: 200px;
            overflow: auto;

            &::-webkit-scrollbar {
              display: none !important;
            }

            .drop {
              padding: 10px 15px;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-family: "hregular";
              line-height: 100%;
              overflow: hidden;
              border-bottom: 1px solid #e6e6e6;

              &:last-child {
                border-bottom: none;
              }

             
            }
          }
        }

        input {
          border: none;
          background: transparent;
          outline: none;
          padding: 15px 20px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;

          &::placeholder {
            color: #96A6AC;
          }
        }

        .search-icon {
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }

      .dropdown {
        max-width: 180px;
        width: 100%;

        .dropdown-toggle {
          padding: 17px;
          width: 100%;
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          box-shadow: none;
          height: 46px;
          border: none;
          color: #000;
          font-family: "hregular";

        

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);

          a {
            padding: 12px 15px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }
        }
      }
    }
  }

  .main-heading2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border-radius: 4px;
      background: var(--Text-Field-Background, #D1DBDF);
      padding: 13px 45px;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      display: flex;
      align-items: baseline;
      justify-content: center;
      gap: 10px;
      border: none;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        position: relative;
        // max-width: 627px;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: var(--Text-Field-Background, #D1DBDF);

        .inputdrop {
          background: transparent;
          background-color: transparent;

          .btn {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: 100%;
            background: transparent;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border: none;
            height: 49px;
            border-right: 1px solid #e6e6e6;
            border-radius: 0px;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            border-radius: 4px;
            background: var(--Text-Field-Background, #D1DBDF);
            padding: 0px;
            margin: 0px;
            max-height: 200px;
            overflow: auto;

            &::-webkit-scrollbar {
              display: none !important;
            }

            .drop {
              padding: 10px 15px;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-family: "hregular";
              line-height: 100%;
              overflow: hidden;
              border-bottom: 1px solid #e6e6e6;

              &:last-child {
                border-bottom: none;
              }

             
            }
          }
        }

        input {
          border: none;
          background: transparent;
          outline: none;
          padding: 15px 20px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
          width: 100%;

          &::placeholder {
            color: #96A6AC;
          }
        }

        .search-icon {
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }

      .dropdown {
        max-width: 180px;
        width: 100%;

        .dropdown-toggle {
          padding: 17px;
          width: 100%;
          box-shadow: none;
          height: 46px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          min-width: 258px;

          a {
            padding: 12px 15px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }

          .custom-option-field {
            input {
              border: none;
            }
          }
        }
      }
    }
  }

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-heading {
      h6 {
        color: #02171D;
        font-family: "f37";
        font-size: 26px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.52px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      p {
        color: #02171D;
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 120%;
        letter-spacing: 0.28px;
        text-transform: uppercase;

        span {
          color: #77868B;
        }
      }
    }

    .btn-back {
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 14px */
    }

    .twice-btn {
      display: flex;
      align-items: center;
      gap: 12px;

      .btn-edit {
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        padding: 13px 30px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .btn-del {
        border-radius: 12px;
        border: 1px solid #ffd0d0;
        background: #fff;
        box-shadow: 0px -5px 0px 0px rgba(254, 0, 0, 0.2) inset;
        padding: 13px 30px;
        color: #fe0000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .inner-heading {
    margin: 20px 0;

    h4 {
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      /* 38.4px */
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 30px;

    .single-card {
      border-radius: 4px;
      background: #ECF5F9;

      flex: 0 0 24.1%;
      padding: 25px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
      position: relative;

      .twice-div {
        width: 100%;
      }

      p {
        color: #77868B;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
        margin-bottom: 10px;
      }

      h6 {
        color: var(--V6-Dark-Theme-Box-Background-Color, #021C24);
        font-family: "f37";
        font-size: 20px;
        font-style: normal;
        line-height: 120%;
        letter-spacing: -0.2px;
      }

      .valuemaindiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        position: relative;
        width: 100%;
        position: absolute;
        bottom: 10px;
        right: 10px;

        .dropdown {
          position: absolute;
          right: 0;
          bottom: 0;

          .btn {
            padding: 0px;
            margin: 0px;
            background: transparent;
            border: none;
            outline: none;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            padding: 0px;
            margin: 0px;
            border-radius: 4px;
            background: #ECF5F9;
            width: 208px;

            .teamsdrops {
              .innerteamdrop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 18px;
                border-bottom: 1px solid #797977;

                .teamdroppara {
                  color: #77868B;
                  font-size: 14px;
                  font-style: normal;
                  font-family: "hregular";
                  line-height: normal;
                  padding: 0;
                }

                .teamdrophead {
                  color: var(--Black, #021C24);
                  font-size: 14px;
                  font-style: normal;
                  font-family: "f37";
                  line-height: normal;
                }
              }
            }
          }
        }

        .valuemain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .valueline {
            border-radius: 4px;
            background: #dfdfdf;
            width: 3px;
            height: 14px;
            display: block;
          }

          .valuepara {
            color: var(--Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.14px;

            .darktext {
              color: var(--Black, #000);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              letter-spacing: -0.14px;
              margin-right: 5px;
            }
          }
        }
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 16.8px */
          letter-spacing: -0.14px;
          margin: 0;

          span {
            background-color: #dfdfdf;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .parent-new-card {
    border-radius: 4px;
    background: #ECF5F9;
    margin: 20px 0;
    padding: 30px;

    .main-headingg {
      h6 {
        color: #000;
        font-family: "f37";
        font-size: 22px;
        font-style: normal;
        line-height: 110%;
        margin-bottom: 30px;
      }
    }
  }

  .bottom-cards1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 34px 90px;

    .inner-text {
      flex: 0 0 240px;

      p {
        color: #77868B;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
        margin-bottom: 12px;
      }

      h6 {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-family: "f37";
        line-height: 120%;
      }
    }
  }

  .bottom-cards2 {
    margin-top: 30px;

    h4 {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      /* 24.2px */
      margin-bottom: 30px;
    }

    .parent-card {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 34px 0;

      .inner-text {
        flex: 0 0 420px;
        min-height: 50px;
        max-height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        p {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 16.8px */
          opacity: 0.6;
          margin-bottom: 12px;
        }

        .style-text {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          margin-bottom: 5px;
          text-decoration: underline;
          /* 19.2px */
        }

        h6 {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          margin-bottom: 5px;
          /* 19.2px */
        }
      }
    }
  }

  .bottom-cards3 {
    .parent-card .inner-text {
      flex: 0 0 310px !important;
    }
  }

  .viewdetail-tabs {
    max-width: 610px;
    width: 100%;
    justify-content: space-between;
    border-radius: 4px;
    background: var(--Tab-Background, #D9E2E5);
    padding: 5px;

    .nav-item {
      flex: 1;

      .nav-link {
        color: #77868B;
        font-size: 16px;
        font-style: normal;
        font-family: "f37";
        line-height: 110%;
        border: none;
        padding: 15px 10px;
        width: 100%;
        border-radius: 4px;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      border-radius: 4px;
      background: var(--Tab-Active-Color, #C1ECE3);
      border: none;
      color: #329879;
      font-size: 16px;
      font-style: normal;
      line-height: 110%;
      /* 17.6px */
    }
  }
}

@media (max-width: 1160px) {
  .viewdetail .bottom-cards1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 991px) {
  .veiwdetaileinnerpage .view-btn {
    display: none !important;
  }

  .viewdetail .main-heading .dropdown {
    display: block !important;
  }

  .viewdetail .dropdown {
    .dropdown-toggle {
      background-color: transparent;
      // border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 4px;
      background: var(--Text-Field-Background, #D1DBDF);
      padding: 0;

      a {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 14px 15px;
      }
    }
  }

  .viewdetail .inner-heading h4 {
    font-size: 32px;
  }

  .viewdetail .inner-heading h4 span {
    font-size: 14px;
  }

  .viewdetail .bottom-cards .single-card {
    flex: 0 0 48.3% !important;
    min-height: 200px;
    padding: 15px;
  }

  .viewdetail .bottom-cards .single-card p {
    font-size: 12px;
  }

  .viewdetail .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .viewdetail .bottom-cards .single-card .bottom-text {
    gap: 15px;
    flex-wrap: wrap;
  }

  .viewdetail .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .viewdetail .bottom-cards1 {
    padding: 0;
    gap: 40px 0;
    justify-content: space-between;
  }

  .viewdetail .parent-new-card {
    padding: 25px;
  }

  .viewdetail .bottom-cards1 .inner-text {
    flex: 0 0 48%;
  }

  .viewdetail .bottom-cards1 .inner-text h6 {
    font-size: 14px;
    // max-width: 120px;
    width: 100%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-all;
  }

  .viewdetail .main-heading111 .rightside-content {
    flex-direction: column;
    gap: 15px;
  }

  .viewdetail .main-heading111 .rightside-content .custom-option-field {
    width: 100%;
  }

  .viewdetail .main-heading111 .rightside-content .dropdown {
    max-width: 100%;
  }

  .viewdetail .main-heading111 .rightside-content .dropdown .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .viewdetail .main-heading2 .rightside-content {
    flex-wrap: wrap;
    gap: 15px;
  }

  .viewdetail .main-heading2 .rightside-content .dropdown {
    max-width: 100%;
    flex: 0 0 47%;
  }

  .viewdetail .bottom-cards2 .parent-card .inner-text {
    flex: 0 0 46%;
  }

  .viewdetail .main-heading111 .rightside-content .custom-option-field {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .viewdetail .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }

  .viewdetail .main-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }

  .viewdetail .main-heading .twice-btn {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }

  .viewdetail .main-heading .twice-btn button {
    width: 100%;
    justify-content: center;
  }

  .viewdetail .bottom-cards1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 376px) {
  .viewdetail .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }

  .viewdetail .viewdetail-tabs .nav-item .nav-link {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .viewdetail .main-heading2 .rightside-content .custom-option-field {
    max-width: 1000px;
  }
}

.set-custom-scrollbar {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.gvcvtdvdvtdcvdtc .custom-option-field {
  max-width: 90% !important;
}

.fxrxxrxxrexxr .custom-option-field {
  width: 100% !important;
}

@media (max-width: 991px) {
  .fxrxxrxxrexxr .custom-option-field {
    width: 100% !important;
  }

  .gvcvtdvdvtdcvdtc .custom-option-field {
    max-width: 100% !important;
  }

  .viewdetail .main-heading2 .rightside-content .dropdown {
    flex: 0 0 100% !important;
  }
}

.allldrop {
  max-width: 131px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .allldrop {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.specialaccess {
  padding-bottom: 20px;
}

.speacial-wrapper {
  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      max-width: 697px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        max-width: 503px;
        width: 100%;
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 46px;
          color: #000;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          background: var(--Text-Field-Background, #D1DBDF);

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .speacial-wrapper .main-heading {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  .speacial-wrapper .main-heading .rightside-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .speacial-wrapper .main-heading .rightside-content .dropdown {
    max-width: 100%;
  }
}

.allldrop {
  max-width: 126px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .allldrop {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.drop-in-search-sticky {
  position: sticky;
  top: -5px;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 9;
}

.btnunblock {
  border: 1px solid #28e664 !important;
  color: #28e664 !important;

  svg path {
    fill: #28e664;
  }
}

.btnunblockmodal {
  background-color: #28e664 !important;
  color: #000 !important;
}

.bottom-cards33 {
  margin-top: 40px;

  h6 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 23px;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

.set-custom-claim-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;

  h6 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .parent-toggle-switch-custom .checkbox:checked+.switch {
    background-color: #fe0000 !important;
  }
}

.codebuylimit {
  margin-bottom: 30px;

  label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: block;
    margin-bottom: 8px;
  }

  input {
    border-radius: 10px;
    border: 1px solid #eee;
    background: #fff;
    padding: 15px 20px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &::placeholder {
      color: #ababab;
    }
  }
}

.codebuylimit-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin: 30px 0;

  h6 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  p {
    color: #404040;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.parent-new-hierarchy {
  $col-1: #e6e6e6;
  $col-2: #fff;
  $col-3: #eefffd;

  ul {
    padding: 0 50px 0 0;
    list-style: none;
    overflow: hidden;
    display: flex;
    font-size: 1.3rem;
  }

  li {
    display: block;
    white-space: nowrap;

    &:first-of-type {

      a,
      .a {
        border-left: 1px solid $col-1;
      }
    }

    &.active {

      a,
      .a {
        background: $col-3;
      }
    }

    .a {
      cursor: default;
    }
  }

  ul {

    a,
    .a {
      border-top: 1px solid $col-1;
      border-bottom: 1px solid $col-1;
      padding: 0 15px 0 60px;
      display: inline-block;
      position: relative;
      text-decoration: none;
      color: $col-1;
      background: $col-2;
    }
  }

  .border-as-triangle {
    li {
      &.active {

        a,
        .a {
          &::before {
            border-left-color: $col-3;
          }

          &::after {
            border-left-color: $col-2;
          }
        }
      }

      a,
      .a {

        &::before,
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 41px solid transparent;
          border-bottom: 41px solid transparent;
          position: absolute;
          top: 50%;
          margin-top: -41px;
          left: 100%;
        }

        &::before {
          border-left: 30px solid $col-2;
          z-index: 2;
        }

        &::after {
          border-left: 30px solid $col-1;
          margin-left: 1px;
          z-index: 1;
        }
      }
    }
  }

  .after-as-rhombus {
    li {
      &.active {

        a,
        .a {
          &::after {
            border-color: $col-2;
          }
        }
      }

      $countLinks: 4;
      $highest: $countLinks;

      @for $i from 1 through $countLinks {
        &:nth-of-type(#{$i}) {
          z-index: $highest;
        }

        $highest: $highest - 1;
      }
    }

    a,
    .a {
      &::after {
        content: "";
        display: block;
        height: 95px;
        width: 95px;
        transform: rotate(45deg) skew(10deg, 10deg);
        position: absolute;
        top: -7px;
        z-index: -1;
        right: -21px;
        z-index: -1;
        background: inherit;
        border: 1px $col-1 solid;
      }
    }
  }

  .clip-path-polygon {
    li {
      &.active {

        a,
        .a {
          &::after {
            background: $col-2;
          }
        }
      }

      $countLinks: 4;
      $highest: $countLinks;

      @for $i from 1 through $countLinks {
        &:nth-of-type(#{$i}) {
          z-index: $highest;
        }

        $highest: $highest - 1;
      }
    }

    a,
    .a {

      &::before,
      &::after {
        content: "";
        display: block;
        height: calc(100% + 2px);
        width: 120px;
        position: absolute;
        top: -1px;
        right: -120px;
        clip-path: polygon(0 0, 0% 100%, 30px 50%);
      }

      &::before {
        right: -119px;
        background: inherit;
        z-index: 1;
      }

      &::after {
        right: -120px;
        background: $col-1;
      }
    }
  }

  a {
    padding: 20px 30px !important;
    width: 250px !important;

    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    h6 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }
  }

  .custom-padding {
    padding-left: 55px !important;
  }
}

@media (max-width: 1300px) {
  .parent-new-hierarchy a {
    width: 220px !important;
  }
}

@media (max-width: 1199px) {
  .parent-new-hierarchy a {
    width: 180px !important;
  }

  .parent-new-hierarchy ul {
    overflow-x: auto;
  }
}

@media (max-width: 991px) {
  .parent-new-hierarchy ul {
    overflow-x: auto !important;
  }

  .parent-new-hierarchy a {
    width: 220px !important;
  }

  .parent-new-hierarchy ul {
    transform: rotate(90deg);
    height: 341px;
    overflow: visible;
  }

  .parent-new-hierarchy li {
    transform: rotate(0deg);
  }

  .parent-new-hierarchy ul a,
  .parent-new-hierarchy ul .a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parent-new-hierarchy a p {
    transform: rotate(270deg);
  }

  .parent-new-hierarchy a h6 {
    transform: rotate(270deg);
  }

  .parent-new-hierarchy a {
    width: 114px !important;
  }

  .parent-new-hierarchy a h6 {
    margin-left: -72px !important;
  }

  .parent-new-hierarchy {
    height: 630px;
  }

  .parent-new-hierarchy .set-custom-margin {
    margin-left: -25px !important;
  }

  .parent-new-hierarchy .set-custom-margin1 {
    margin-left: -54px !important;
  }

  .parent-new-hierarchy a p {
    max-width: 200px;
    margin: 0 auto;
  }
}

.new-leaders-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 25px;

  .single-card-diff {
    max-width: 425px;
    width: 100%;
    border-radius: 4px;
    background: #ECF5F9;
    padding: 30px 25px;

    p {
      color: #77868B;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    h6 {
      color: #021C24;
      font-size: 20px;
      font-style: normal;
      font-family: "f37";
      line-height: 120%;
      letter-spacing: -0.2px;
    }
  }

  .multi-parent {
    flex: 1;
    border-radius: 4px;
    background: #ECF5F9;
    padding: 30px 36px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 190px;

    p {
      color: #77868B;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    h6 {
      color: #021C24;
      font-size: 20px;
      font-style: normal;
      font-family: "f37";
      line-height: 120%;
      letter-spacing: -0.2px;
    }
  }
}

@media (max-width: 1024px) {
  .new-leaders-detail {
    flex-direction: column;
  }

  .new-leaders-detail .multi-parent {
    flex-wrap: wrap;
    gap: 50px 0;
    width: 100%;
    flex: auto;
  }

  .new-leaders-detail .multi-parent .single-card {
    flex: 0 0 48%;
  }
}