@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

@font-face {
  font-family: "f37";
  src: url("../src/font/incise/f37incisemedium.ttf");
}

@font-face {
  font-family: "bl";
  src: url("../src/font/blarctic/blarctic.otf");
}

@font-face {
  font-family: "hlight";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Light.ttf");
}

@font-face {
  font-family: "hregular";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Regular.ttf");
}

@font-face {
  font-family: "hmedium";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Medium.ttf");
}

@font-face {
  font-family: "hsemibold";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Bold.ttf");
}

@font-face {
  font-family: "hbold";
  src: url("../src/font/helveteca/HelveticaNowDisplay-ExtraBold.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  background: #e2e9ec;
  position: relative;
  min-height: 100vh;
}

.leftimg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: -9;
}

.rightimg {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -9;
}

.greybtn {
  border-radius: 6px;
  background: var(--Primary-Green, #329879);
  border: none;
  display: flex;
  padding: 18px 25px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  transition: 0.3s linear;
  color: #e1e9ec;
  font-size: 14px;
  font-style: normal;
  font-family: "hregular";
  line-height: 100%;

  &:hover {
    border-radius: 4px;
    background: #1e7e61;
  }

  &:active {
    border-radius: 4px;
    background: #1e7e61;
    box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5);
  }
}

.whitebtn {
  border-radius: 4px;
  background: #d1dbdf;
  display: flex;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  color: #02171d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-family: "hmedium";
  line-height: 100%;
  text-transform: uppercase;
  outline: none;
  border: none;
}

.blackbtn {
  border-radius: 15px;
  background: #000;
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  display: flex;
  padding: 18px 25px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

button {
  cursor: pointer !important;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

button.close {
  opacity: 1;
}

.theme-container {
  max-width: 1370px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

iframe {
  // display: none !important;
}

// input styling scss here..........................................

.material-textfield {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.material-textfield label {
  color: var(--Primary-Text-Color, #02171d);
  font-size: 14px;
  font-style: normal;
  font-family: "hmedium";
  line-height: 100%;
  margin-bottom: 15px;
  display: block;
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border-radius: 4px;
  background: var(--Text-Field-Background, #d1dbdf);
  padding: 20px 18px;
  width: 100%;
  box-shadow: none !important;
  color: #000;
  font-family: "hregular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  border: none;

  &:hover {
    &::placeholder {
      color: #000;
      transition: 0.3s;
    }
  }

  &::placeholder {
    color: rgba(2, 23, 29, 0.5);
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }
}

.checkboxmain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  .checkboxpara {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .checkbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1.5px solid #eee;
    background: #fff;
    transition: all 0.2s ease;
  }

  .checkbox input:checked ~ .checkmark {
    background: #4cead7;
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
  }

  .checkbox .checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 5.5px;
    height: 12px;
    border: solid #1c1c1c;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .checkbox input:checked ~ .checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}

.checkboxmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .clear {
    clear: both;
  }

  .checkBox {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 3.333px;
    border: 0.833px solid rgba(2, 23, 29, 0.7);
  }

  .checkBox:has(input:checked) {
    border: 0.833px solid #329879;
  }

  .checkBox div {
    width: 60px;
    height: 60px;
    border-radius: 2px;
    background: #329879;
    border: 0.833px solid #329879;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }

  .checkBox input[type="checkbox"]:checked + div {
    left: -10px;
    top: -10px;
  }

  .checkBox input[type="checkbox"] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }

  .transition {
    transition: 300ms ease;
  }

  .checkboxpara {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
  }
}

.custom-switch-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  h6 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .parent-toggle-switch-custom .switch {
    background-color: #000 !important;
  }

  .parent-toggle-switch-custom .slider {
    background-color: #000 !important;
  }
}

.parent-toggle-switch-custom {
  /* The switch - the box around the slider */
  .custom-toggle {
    width: 51px;
    height: 31px;
    position: relative;
  }

  /* Hide default HTML checkbox */
  .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  .redddd {
    background: #fe0000 !important;
  }

  .switch {
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(178, 190, 195, 0.1);
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }

  /* The slider */
  .slider {
    width: 27px;
    height: 27px;
    position: absolute;
    left: calc(50% - 27px / 2 - 10px);
    top: calc(50% - 27px / 2);
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-out;
    cursor: pointer;
  }

  .checkbox:checked + .switch {
    background-color: #28e664;
  }

  .checkbox:checked + .switch .slider {
    left: calc(50% - 27px / 2 + 10px);
    top: calc(50% - 27px / 2);
  }
}

.custom-switch-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  h6 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .parent-toggle-switch-custom .switch {
    background-color: #e6e6e6 !important;
  }

  .parent-toggle-switch-custom .slider {
    background-color: #000 !important;
  }
}

.modal-backdrop {
  background: var(--Pop-Up-Background-Color, rgba(167, 182, 187, 0.65));
  backdrop-filter: blur(7.5px);
  opacity: 1 !important;
}

.global-modal-scss {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(5px);

  .modal-content {
    border-radius: 15px;
    background: #fff;
    padding: 25px;
  }

  .modal-header {
    border: none;
    padding: 0px;
    margin-bottom: 30px;

    .modal-title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
    }

    .btn-close {
      // background: url("../src/assets/close-circle-modal.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
      box-shadow: none;
    }
  }

  .modal-body {
    padding: 0;
  }

  .twice-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      flex: 0 0 48%;
    }

    .btn-cancel {
      border-radius: 15px;
      border: 1px solid #e6e6e6;
      background: #fff;
      box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
      padding: 14px;
      width: 100%;
      border: none;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn-del {
      background: #fe0000;
      border: none;
      padding: 14px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .btn-add {
      border-radius: 15px;
      background: #4cead7;
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
      border: none;
      padding: 14px;
      width: 100%;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      text-transform: uppercase;
    }
  }
}

@media (max-width: 600px) {
  .leftimg {
    max-width: 188.889px;
    width: 100%;
    height: 143.444px;
  }

  .rightimg {
    max-width: 188.889px;
    width: 100%;
    height: 143.444px;
  }
}

.set-custom-calendar-div {
  position: relative;

  .rdrCalendarWrapper {
    position: absolute;
    right: 0;
    top: 30px;
    background-color: #fff;
    z-index: 9999;
  }
}

.active > .page-link,
.page-link.active {
  background-color: #c1ebe2 !important;
  border-radius: 5px !important;
  color: #329879 !important;
}

.disabled > .page-link,
.page-link.disabled {
  opacity: 0.5;
}

.loadertext {
  color: #fff;
  margin-top: 35px;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.1px;
}

.paddiinput {
  padding-right: 50px !important;
}

.fornewgap {
  gap: 20px !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.tooltip-popup {
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  color: var(--Black, #000);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.28px;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: 9999;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.walletinputs {
  background: #fafafa !important;
}

.walletlabel {
  background: #fafafa !important;
}

.hideabtn {
  .upperbtn {
    display: none !important;
  }
}

.telegram-modal-popup {
  .addedmodal {
    .inside-content {
      position: relative;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 6px;
      height: 240px;
      overflow: hidden;
      margin-bottom: 10px;

      .telegram-popup-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .edbyefybyef {
        margin-top: 30px;
      }

      h6 {
        color: #fff;
        text-align: center;
        font-family: "F37";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h5 {
        color: #fff;
        text-align: center;
        font-family: "F37";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.2px;
        text-transform: uppercase;
      }
    }
  }
}

.custom-container {
  max-width: 1200px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.image-preview {
  iframe {
    display: block !important;
    width: 100% !important;
    height: 400px !important;
  }

  .modal-content {
    background-color: transparent !important;
  }

  .modal-header {
    padding: 0;
    border: none;

    .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: url("../src/assets/btn-close-preview.svg") !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      opacity: 1;
      z-index: 999;
    }
  }

  .modal-dialog {
    max-width: 700px;
  }
}

.newbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  height: 40px;

  .mywidth {
    border-radius: 4px;
    background: #329879;
    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    color: var(--Black, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-family: "hmedium";
    text-transform: capitalize;
    line-height: 21px;
    letter-spacing: 0.28px;
    width: 100%;
    gap: 10px;
    height: 100%;
  }
}

.custom-checkbox {
  .form-group input:checked + label {
    color: #02171d;
  }

  .form-group {
    display: flex;
    align-items: center;

    &:hover {
      label {
        color: var(--Primary-Text-Color, #02171d);
        transition: 0.3s;
      }

      label:before {
        content: "";
        border: 1px solid #02171d;
        transition: 0.3s;
      }
    }
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: var(--Secondary-Text-Color, #77868b);
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #485a5f;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  .form-group input:checked + label::before {
    background-color: #329879;
    border: 1px solid transparent;
  }

  label:checked + label::before {
    content: "";
    color: var(--Primary-Text-Color, #02171d) !important;
  }

  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 7px;
    height: 11px;
    border: solid #ecf5f9;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  a {
    color: #77868b;
    font-size: 14px;
    font-style: normal;
    font-family: "hmedium";
    line-height: normal;
  }
}

.maintopshadow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

* {
  --wcm-z-index: 99999999;
}

.checkboxmainnnnnn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;

  .checkboxpara {
    font-family: "hregular";
    color: var(--Primary-Text-Color, #02171d);
    font-size: 14px;
    font-style: normal;
    line-height: 117%;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .checkbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 1.5px solid #485a5f;
    border-radius: 4px;
    transition: all 0.2s ease;
  }

  .checkbox input:checked ~ .checkmark {
    background: #329879;
    border: 1.5px solid transparent;
  }

  .checkbox .checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 45%;
    width: 5.5px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .checkbox input:checked ~ .checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}
