.wrapper-login {
    position: relative;
    .mainnavbar {
        border-bottom: 1px solid var(--Light-Mode-Strock-Color, #C1CBCE);
        background: rgba(209, 218, 221, 0.30);
        backdrop-filter: blur(15px);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .loginfooter{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99;
    }
}

.mainsignup {
    padding-top: 152px;
    position: relative;
    z-index: 99;
    padding-bottom: 100px;
    min-height: 100vh;

    .video-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .main-banner-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
        pointer-events: none;
    }

    .lastparasignup {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        bottom: 27px;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        white-space: nowrap;
    }

    .mainsignupdiv {
        display: flex;
        flex-direction: column;
        align-items: center;

        .signuplogo {
            width: 122px;
            height: 33px;
            margin: 0 auto 28px;
        }

        .innersignup {
            width: 100%;
            display: flex;
            max-width: 514px;
            padding: 32px 20px;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 61px;
            border-radius: 4px;
            border: 1px solid #F1F1F1;
            background: #ECF5F9;

            .gapbtn {
                gap: 10px;
            }

            .lowerlogin {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 22px;

                .forgotpara {
                    color: #111;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }

            .material-textfield {
                .eyeimg {
                    position: absolute;
                    top: 16px;
                    right: 12px;
                }

                .connectwalletbtn {
                    color: #4CEAD7;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    position: absolute;
                    top: 19px;
                    right: 12px;
                    cursor: pointer;
                }

                .green {
                    color: #329879 !important;
                }

                .paddright {
                    padding-right: 120px;
                }
            }

            .logo-green {
                display: block;
                margin: 0 auto;
            }

            .signuphead {
                color: #02171D;
                text-align: center;
                font-size: 26px;
                font-family: "f37";
                font-style: normal;
                line-height: normal;
                margin: 30px 0;
            }

            .custom-checkbox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
}

.walletconnectmodal {
    .modal-dialog {
        .modal-content {
            border-radius: 4px;
            background: #E2E9EC;


            .modal-header {
                padding: 20px;
                border-bottom: 1px solid #797977;

                .modal-title {
                    color: #02171D;
                    font-family: "f37";
                    font-size: 20px;
                    font-style: normal;
                    line-height: 120%;
                }

                .btn-close {
                    background: url(../../assets/signupassets/close-circle.svg);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 24px;
                    box-shadow: none !important;
                    outline: none !important;
                    padding: 0px;
                    margin: 0px;
                    height: 24px;
                    opacity: unset;
                }
            }

            .modal-body {
                padding: 20px;

                .walletmain {
                    display: flex;
                    align-items: flex-start;
                    gap: 15px;
                    margin-top: 20px;

                    .walletinner {
                        border-radius: 4px;
                        background: #FFF;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        height: 185px;
                        gap: 12px;
                        cursor: pointer;

                        .walletpara {
                            color: #000;
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-family: "hmedium";
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:600px) {
    .mainsignup .mainsignupdiv .innersignup {
        padding: 25px 15px;
    }

    .mainsignup .mainsignupdiv .innersignup .signuphead {
        font-size: 18px;
    }

    .mainsignup .lastparasignup {
        font-size: 12px;
    }

    .walletconnectmodal .modal-dialog .modal-content .modal-body .walletmain {
        flex-direction: column;
    }

    .walletconnectmodal .modal-dialog .modal-content .modal-body .walletmain .walletinner {
        width: 100%;
    }
}