.main-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 100vh;
    padding: 0px 15px;
    position: relative;

    .innerbannercard {
        border-radius: 15px;
        border: 1px solid #797977;
        background: #FFF;
        box-shadow: 0px -8px 0px 0px rgba(0, 0, 0, 0.06) inset;
        display: flex;
        padding: 40px 30px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 421px;
        width: 100%;

        .innercardhead {
            color: #000;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .dullbtn {
            border-radius: 15px;
            background: #F8F8F8;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
            display: flex;
            padding: 18px 25px;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: #48DBCA;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
            border: none;
        }

        .dulltext {
            color: #E0E0E4;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-top: 20px;

            .dullbold {
                color: #E0E0E4;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        .blacktext {
            color: #84848E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-top: 20px;

            .blackbold {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        .innercardpara {
            color: #84848E;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 30px;
        }
    }
}

@media (max-width:786px) {
    .main-banner {
        flex-direction: column;
        padding: 50px 15px;
    }
}