.datatable {
    margin-top: 36px;

    .newtable {
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        background: #FFF;

        .newtableinner {
            border-radius: 0px;
            border: none;
            background: transparent;
        }
    }


    .main-heading {
        margin: 30px 0;

        h6 {
            color: #000;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.44px;
        }
    }

    .table-responsive {

        .dropdown {
            .btn {
                background: transparent;
                border: none;
                border-radius: 0px;
                padding: 0;
                margin: 0;
            }

            .dropdown-menu {
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                background: #FFF;
                box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
                width: 157px;
                padding: 0px;
                overflow: hidden;

                .blacktext {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-bottom: 1px solid #797977;
                    display: flex;
                    padding: 12px 18px;
                    flex-direction: column;
                    align-items: flex-start;
                    cursor: pointer;

                    &:hover {
                        border-bottom: 1px solid #797977;
                        background: #F9F9F9;
                    }
                }

                .redtext {
                    cursor: pointer;
                    color: #FE0000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-bottom: 1px solid #797977;
                    display: flex;
                    padding: 12px 18px;
                    flex-direction: column;
                    align-items: flex-start;

                    &:hover {
                        border-bottom: 1px solid #797977;
                        background: #F9F9F9;
                    }
                }
            }
        }

        .table {
            margin: 0;
        }

        th {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            /* 171.429% */
            letter-spacing: 0.28px;
            vertical-align: middle !important;
            border: none;
            padding: 28px 20px;
            position: relative;
            white-space: nowrap;
            background-color: transparent;

            &:last-child {
                text-align: end !important;
                padding-right: 82px;
            }
        }

        td {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 171.429% */
            letter-spacing: 0.1px;
            vertical-align: middle !important;
            border-top: 1px solid #FFF;
            padding: 0;
            padding: 30px 20px;
            white-space: nowrap;
            background-color: transparent;

            .teammainitexts {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .teammainimg {
                    border-radius: 30px;
                    display: flex;
                    width: 44px;
                    height: 44px;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                    object-position: center;

                    .teaminnerimg {
                        border-radius: 30px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                }

                .teamtexts {
                    .teamhead {
                        color: #000;
                        margin-bottom: 4px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: 0.24px;
                    }

                    .teampara {
                        color: #329879;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: 0.24px;
                    }
                }

            }

            .twice-text {
                h6 {
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                    /* 175% */
                    letter-spacing: 0.24px;
                }

                p {
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    /* 175% */
                    letter-spacing: 0.24px;
                    margin-top: 2px;
                }
            }


            &:last-child {
                margin-left: auto;
            }

            .forend {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .common-btn {
                    padding: 13px 25px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                }
            }
        }


    }

    .footer-content {
        border-radius: 0px 0px 4px 4px;
        background: var(--Box-Background-Color, #ECF5F9);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 22px;
  
        .left-f {
          h6 {
            color: var(--Secondary-Text-Color, #77868B);
            font-family: "hmedium";
            font-size: 12px;
            font-style: normal;
            line-height: 110%; 
            letter-spacing: 0.1px;
          }
        }
  
        .right-f {
            .pagination {
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
          .page-link:focus {
            box-shadow: none;
            background-color: unset;
          }
  
          .page-link:hover {
            box-shadow: none;
            background-color: unset;
          }
  
          .page-link {
            color: #869397;
            font-family: "f37";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            border: none;
            outline: none;
            box-shadow: none;
            background: transparent;
            padding: 6px 11px;
  
            &.active {
              background: #329879;
              border-radius: 5px;
              background: #C1EBE2;
            }
          }
        }
      }
}


.filter-btn {
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width:650px) {
    .datatable .footer-content .left-f h6 {
        display: none !important;
    }

    .myteam .parent .right .innerright{
        width: 100%;
    }
    .myteam .parent .right .innerright input {
        max-width: 100%;
    }
    .myteam .parent .right{
        width: 100%;
    }
    .myteam .parent .right .dropbtn .dropdown .dropdown-toggle{
        max-width: 100%;
        width: 100% !important; 
    }
    .myteam .parent .right .dropbtn{
        width: 100%;
        .dropdown{
            width: 100%;
        }
    }
    .myteam .parent .right {
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .myteam .parent {
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .datatable .footer-content {
        justify-content: center;
    }

    .accmblview {
        display: block !important;
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        background: #FFF;

        .main-twice-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 28px;
            padding-right: 61px;

            .accmblviewhead {
                color: #000;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.28px;
                display: flex;
                justify-content: flex-start;
                align-items: center;


                .arrowsacc {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }



        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                // background: url("../../../../assets/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
                position: absolute;
                top: 28px;
                right: 25px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;


                    .accordion-button {
                        border-top: 1px solid #FFF;
                        border-bottom: 1px solid #FFF;
                        background: var(--login-bg);
                        padding: 22px 25px;
                        padding-right: 61px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 30px;
                            width: 100%;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 100%;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 0px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 0px;
                        border-bottom: 1px solid #1C1C1C;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                            text-transform: uppercase;
                        }

                        .textright {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }

                        .brdrbtn {
                            border: 1px solid #FFF;
                            background: #000;
                            box-shadow: 2px 2px 0px 0px #FFF;
                            display: flex;
                            padding: 7px 15px;
                            justify-content: center;
                            align-items: center;
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .twice-text {
            h6 {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
            }

            p {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
                margin-top: 2px;
            }
        }
    }

    .accmblview .accordion .accordion-item .accordion-header .accordion-button {
        box-shadow: none !important;
    }

    .footer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // border-top: 1px solid #797977;
        background-color: transparent;

        .left-f {
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }

        .right-f {
            .page-link:focus {
                box-shadow: none;
                background-color: unset;
            }

            .page-link:hover {
                box-shadow: none;
                background-color: unset;
            }

            .page-link {
                border: none;
                color: #A2A4A6;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 15px */
                background-color: transparent;

                &.active {
                    border-radius: 5px;
                    background: #000;
                    box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.20) inset;
                    color: #fff;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }
    }

    .dashboardetail .auctiondetailhead {
        font-size: 20px;
    }
}