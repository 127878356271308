.mainprofile {
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 100vh;

  .profileparent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    max-width: 1016px;
    width: 100%;
    margin: 0 auto;

    .left {
      width: 50%;

      .uploadedimg {
        border-radius: 300px;
        border: 1px solid #e6e6e6;
        display: flex;
        width: 132px;
        height: 132px;
        padding: 4.4px;
        justify-content: center;
        align-items: center;
        background: #fff;
        object-fit: cover;
        object-position: center;

        .uploadedinnerimg {
          border-radius: 300px;
          width: 100%;
          height: 100%;
          object-fit: fill;
          object-position: center;
        }
      }

      .profilecard {
        border-radius: 4px;
        background: #ECF5F9;
        padding: 25px 20px;
        width: 100%;
        margin-bottom: 15px;

        .inner {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          h3 {
            margin-top: 20px;
            margin-bottom: 4px;
            color: var(--Primary-Text-Color, #02171D);
            text-align: center;
            font-family: "f37";
            font-size: 20px;
            font-style: normal;
            line-height: 24px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
          }

          p {
            color: #329879;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.28px;
            text-transform: uppercase;
            margin-bottom: 18px;
          }

          a {
            width: 100%;
          }

          button {
            border-radius: 4px;
            background: var(--Text-Field-Background, #D1DBDF);
            padding: 10px 14px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 120%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            max-width: 100%;
            width: 100%;
            border: none;
          }
        }
      }

      .secondcard {
        border-radius: 4px;
        background: #ECF5F9;
        padding: 20px;

        .innerparent {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .first {
            h3 {
              color: #77868B;
              margin-bottom: 10px;
              font-size: 12px;
              font-style: normal;
              font-family: "hregular";
              line-height: 120%;
              text-transform: uppercase;
            }

            p {
              color: #02171D;
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 120%;
            }
          }
        }
      }
    }

    .right {
      width: 50%;

      .mainssss {
        width: 100%;
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        padding: 5px;
        width: 100%;
        max-width: fit-content;

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
          border-radius: 8px;
          background: #000;
          padding: 12px 10px;
          box-shadow: 0px -5px 0px 0px rgba(255, 255, 255, 0.2) inset;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%;
          max-width: 328px;
          width: 100%;
          text-align: center;
        }

        .nav-link {
          color: #000;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          padding: 12px 10px;
          line-height: 110%;
          white-space: nowrap;
        }

        .nav .nav-pills {
          width: 100%;
        }

        .nav-item {
          width: 100%;
        }
      }

      .tabcard {
        border-radius: 4px;
        background: #ECF5F9;
        padding: 20px;
        width: 100%;
        margin: 15px 0px;

        &:last-child {
          margin-bottom: 0px !important;
        }

        h2 {
          color: #02171D;
          font-size: 20px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
          margin-bottom: 18px;
        }

        .para {
          color: #77868B;
          margin-bottom: 12px;
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;

          &:last-child {
            margin-bottom: 0px !important;
          }
        }

        .foralign {
          align-items: center !important;
        }

        .parent {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          .first {
            h3 {
              color: #77868B;
              font-size: 12px;
              font-style: normal;
              font-family: "hregular";
              line-height: 120%;
              text-transform: uppercase;
              margin-bottom: 10px;
            }

            p {
              color: #02171D;
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 120%;
            }

            .toggle-switch {
              position: relative;
              display: inline-block;
              width: 40px;
              height: 24px;
              margin: 10px;
            }

            /* Giriş stil */
            .toggle-switch .toggle-input {
              display: none;
            }

            /* Anahtarın stilinin etrafındaki etiketin stil */
            .toggle-switch .toggle-label {
              position: absolute;
              top: 0;
              left: 0;
              width: 44px;
              height: 24px;
              background-color: #329879;

              border-radius: 16px;
              cursor: pointer;
              transition: background-color 0.3s;
            }

            /* Anahtarın yuvarlak kısmının stil */
            .toggle-switch .toggle-label::before {
              content: "";
              position: absolute;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              top: 2px;
              left: 2px;
              background: #ffffff;
              transition: transform 0.3s;
            }

            /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
            .toggle-switch .toggle-input:checked+.toggle-label {
              background-color: #329879;
            }

            .toggle-switch .toggle-input:checked+.toggle-label::before {
              transform: translateX(20px);
            }

            /* Light tema */
            .toggle-switch.light .toggle-label {
              background-color: #bebebe;
            }

            .toggle-switch.light .toggle-input:checked+.toggle-label {
              background-color: #9b9b9b;
            }

            .toggle-switch.light .toggle-input:checked+.toggle-label::before {
              transform: translateX(6px);
            }

            /* Dark tema */
            .toggle-switch.dark .toggle-label {
              background-color: #4b4b4b;
            }

            .toggle-switch.dark .toggle-input:checked+.toggle-label {
              background-color: #717171;
            }

            .toggle-switch.dark .toggle-input:checked+.toggle-label::before {
              transform: translateX(16px);
            }
          }
        }
      }
    }
  }
}

/////////////.........editprofileScss.........///////////

.editprofile {
  padding-top: 60px;
  padding-bottom: 32px;

  .mainparent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .parent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      max-width: 653px;
      width: 100%;

      .right {
        h3 {
          color: var(--Primary-Text-Color, #02171D);
          font-family: "f37";
          font-size: 20px;
          font-style: normal;
          line-height: 120%;
        }

        p {
          color: #84848e;
          margin-top: 5px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .innercard {
      border-radius: 15px;
      background: #ECF5F9;
      padding: 25px;
      width: 100%;
      max-width: 653px;
      margin-top: 20px;

      .innercardparent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 25px;
        margin-bottom: 22px;

        .uploadedimg {
          border-radius: 300px;
          border: 1px solid #E6E6E6;
          background: #FFF;
          display: flex;
          width: 132px;
          height: 132px;
          padding: 4.4px;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          object-position: center;

          .uploadedinnerimg {
            border-radius: 300px;
            width: 100%;
            height: 100%;
            object-fit: fill;
            object-position: center;
          }
        }

        .right {
          h2 {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 6px;
          }

          p {
            color: #8a8c90;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 18px;
            max-width: 205px;
            width: 100%;
          }

          .uploadbtn {
            border-radius: 4px;
            background: var(--Text-Field-Background, #D1DBDF);
            padding: 10px 14px;
            max-width: 205px;
            width: 100%;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 120%;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      .inputparent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 18px;
        gap: 16px;

        .muinput {
          width: 50%;
        }
      }

      .inputparent1 {
        position: relative;
        margin-top: 18px;

        textarea {
          border-radius: 4px;
          background: var(--Text-Field-Background, #D1DBDF);
          padding: 16px 12px;
          height: 100px;
          width: 100%;
          border: none;
        }

        .muinput {
          width: 100%;
        }

        .blue {
          color: #329879;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          position: absolute;
          top: 53px;
          right: 20px;
          cursor: pointer;
        }

        .copied-text {
          text-align: right;
          margin-top: -15px;
          color: #329879;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .btnnn {
        button {
          border-radius: 4px;
          background: #329879;
          padding: 17px 35px;
          width: 100%;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 100%;
          text-transform: capitalize;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .mainprofile .profileparent {
    flex-direction: column;
  }

  .mainprofile .profileparent .left {
    width: 100%;
  }

  .mainprofile .profileparent .right {
    width: 100%;
  }

  .mainprofile .profileparent .right .tabcard .parent {
    flex-direction: column;
    align-items: center;
  }

  .mainprofile .profileparent .right .tabcard .parent .first {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  .editprofile .mainparent .innercard .inputparent {
    flex-direction: column;
  }

  .editprofile .mainparent .innercard .inputparent .muinput {
    width: 100%;
  }

  .mainprofile .profileparent .right .tabcard .parent {
    justify-content: center;
  }

  .mainprofile .profileparent .right .tabcard .parent .first h3 {
    text-align: center;
  }

  .mainprofile .profileparent .right .tabcard .parent .first p {
    text-align: center;
  }
}