.mainclaims {
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 100vh;

  .parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    z-index: 0;
    position: relative;

    .left {
      img {
        border-radius: 140px;
        width: 44px;
      }

      h2 {
        color: #000;
        font-family: "f37";
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      .innerright {
        position: relative;

        img {
          position: absolute;
          top: 14px;
          right: 20px;
        }

        input {
          border-radius: 10px;
          border: 1px solid #e6e6e6;
          background: #fff;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
          width: 100%;
          max-width: 241px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          padding: 15px 20px;

          &::placeholder {
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }
      }

      .dropbtn {
        .dropdown {
          .dropdown-toggle::after {
            display: none !important;
          }

          .dropdown-toggle {
            max-width: 188px;
            width: 100%;
            border-radius: 12px;
            border: 1px solid #e6e6e6;
            background: #fff;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            padding: 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #000 !important;
            width: 188px !important;
            text-align: start;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: start;
          }

          .dropdown-menu {
            background: #ffffff;
            border: 1px solid #dfe6e9;
            border-radius: 7px;
            padding: 16px 12px 0px;
            width: 100%;
            margin-top: 6px;
          }

          .dropdown-item {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            color: #2d3436;
            border-bottom: 1px solid #e6e6e6 !important;
            margin-bottom: 10px;
            padding: 0px;
            padding-bottom: 10px;

            &:last-child {
              border-bottom: none !important;
            }
          }
        }

        /* Genel stil */

        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 24px;
          margin: 10px;
        }

        /* Giriş stil */
        .toggle-switch .toggle-input {
          display: none;
        }

        /* Anahtarın stilinin etrafındaki etiketin stil */
        .toggle-switch .toggle-label {
          position: absolute;
          top: 0;
          left: 0;
          width: 44px;
          height: 24px;
          background-color: #b2bec3;
          border-radius: 16px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        /* Anahtarın yuvarlak kısmının stil */
        .toggle-switch .toggle-label::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          background: #ffffff;
          transition: transform 0.3s;
        }

        /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
        .toggle-switch .toggle-input:checked + .toggle-label {
          background-color: #0010f7;
        }

        .toggle-switch .toggle-input:checked + .toggle-label::before {
          transform: translateX(20px);
        }

        /* Light tema */
        .toggle-switch.light .toggle-label {
          background-color: #bebebe;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label {
          background-color: #9b9b9b;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label::before {
          transform: translateX(6px);
        }

        /* Dark tema */
        .toggle-switch.dark .toggle-label {
          background-color: #4b4b4b;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label {
          background-color: #717171;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label::before {
          transform: translateX(16px);
        }
      }
    }
  }

  .card {
    border-radius: 15px;
    border: 1px solid #797977;
    background: #fff;
    box-shadow: 0px -8px 0px 0px rgba(0, 0, 0, 0.06) inset;
    padding: 50px 30px;
    width: 100%;
    z-index: -1;

    .innercontent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h3 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        margin-top: 15px;
      }

      p {
        color: #84848e;
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .main-card {
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    width: 100%;
    background: #fff;

    .footer-content {
      border-radius: 0px 0px 4px 4px;
      background: var(--Box-Background-Color, #ecf5f9);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 22px;

      .left-f {
        h6 {
          color: var(--Secondary-Text-Color, #77868b);
          font-family: "hmedium";
          font-size: 12px;
          font-style: normal;
          line-height: 110%;
          letter-spacing: 0.1px;
        }
      }

      .right-f {
        .pagination {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .page-link:focus {
          box-shadow: none;
          background-color: unset;
        }

        .page-link:hover {
          box-shadow: none;
          background-color: unset;
        }

        .page-link {
          color: #869397;
          font-family: "f37";
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          border: none;
          outline: none;
          box-shadow: none;
          background: transparent;
          padding: 6px 11px;

          &.active {
            background: #329879;
            border-radius: 5px;
            background: #c1ebe2;
          }
        }
      }
    }

    .table-responsive {
      .table {
        margin: 0px;
      }

      th {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        vertical-align: middle;
        border: none;
        white-space: nowrap;

        img {
          margin-left: 8px;
        }
      }

      td {
        white-space: nowrap;
        border-top: 1px solid rgba(3, 2, 41, 0.05);
        vertical-align: middle;

        .green {
          color: var(--Green, #329879);
          font-family: Space Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          /* 150% */
          letter-spacing: 0.28px;
        }

        .yellow {
          color: var(--Orange, #f90);
          font-family: Space Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.28px;
        }

        .red {
          color: #fc4f52;
          font-family: Space Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.28px;
        }

        .parenttbl {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;

          .left {
            img {
              width: 44px;
              border-radius: 140px;
              max-width: 44px;
            }
          }

          .right {
            h6 {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: 0.24px;
              margin-bottom: 0px;
            }

            p {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.28px;
              margin-top: 4px;
              margin-bottom: 0px;
            }
          }
        }

        .para {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 11px;
          letter-spacing: 0.28px;
        }

        h6 {
          color: #000;
          margin-bottom: 0px;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0.28px;
        }

        .round {
          color: #000;
          font-family: Space Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.28px;
        }

        p {
          color: #000;
          margin-top: 2px;
          margin-bottom: 0px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.28px;
        }

        .claim {
          border-radius: 15px;
          background: #329879;
          padding: 15px 35px;
          box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
          max-width: 139px;
          width: 100%;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          border: none;
          display: block;
          margin: 0 auto;
        }

        .claimone {
          border-radius: 15px;
          padding: 15px 35px;
          background: #f8f8f8;
          box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
          max-width: 139px;
          width: 100%;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          border: none;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .claim-navtab {
    .nav-tabs {
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      max-width: 600px;
      width: 100%;
      justify-content: space-between;
      padding: 5px;

      .nav-link {
        padding: 15px 10px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
      }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      border-radius: 8px;
      background: #000;
      box-shadow: 0px -7px 0px 0px rgba(255, 255, 255, 0.2) inset;
      color: #fff;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.claimmodal {
  .modal-dialog {
    max-width: 606px;

    .modal-content {
      padding: 0px;
      border-radius: 4px;
      background: #e2e9ec;

      .modal-header {
        border-bottom: 1px solid #797977;
        padding: 24px 20px;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-title {
          color: #000;
          font-family: "Space Grotesk";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }

        .btn-close {
          background: url(../../assets/close-circle.svg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: contain;
          width: 17.844px;
          height: 18.603px;
          box-shadow: none !important;
          opacity: unset;
          position: absolute;
          padding: 0px;
          margin: 0px;
          top: 26px;
          right: 20px;
        }
      }

      .modal-body {
        padding: 25px 20px 39px;

        .modalcard {
          border-radius: 4px;
          background: var(--Text-Field-Background, #d1dbdf);
          padding: 20px 15px;
          width: 100%;
          margin-bottom: 15px;

          .cardparent {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h3 {
              color: var(--Secondary-Text-Color, #77868b);
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 21px;
              letter-spacing: 0.28px;
              margin-bottom: 10px;
            }

            h5 {
              color: var(--Primary-Text-Color, #02171d);
              font-family: "f37";
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0.48px;
              margin-bottom: 10px;
            }

            h6 {
              color: var(--Primary-Text-Color, #02171d);
              font-family: "hmedium";
              font-size: 16px;
              font-style: normal;
              line-height: 21px;
              letter-spacing: 0.32px;
              text-align: center;
            }

            p {
              color: #000;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 21px;
              letter-spacing: 0.4px;
            }

            .btn-detail {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0.28px;
              text-decoration: underline !important;
              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
            }
          }
        }

        .twice-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          width: 100%;

          .inner-main {
            width: 100%;
            border-radius: 4px;
            background: var(--Text-Field-Background, #d1dbdf);
            padding: 20px 15px;
          }
        }

        .claimall {
          color: var(--Primary-Text-Color, #02171d);
          font-family: "f37";
          font-size: 18px;
          font-style: normal;
          line-height: 21px;
          letter-spacing: 0.36px;
          margin-bottom: 12px;
        }

        .para {
          color: var(--Primary-Text-Color, #02171d);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 21px;
          letter-spacing: 0.28px;
          margin-bottom: 20px;
        }

        .claimbtn {
          border-radius: 4px;
          background: var(--Text-Field-Background, #d1dbdf);
          padding: 16px 17.059px;
          width: 100%;
          color: var(--Primary-Text-Color, #02171d);
          text-align: center;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          border: none;
          outline: none;
          text-transform: capitalize;
        }

        .greenbtn {
          border-radius: 4px;
          background: var(--Primary-Green, #329879);
          display: flex;
          width: 100%;
          padding: 16px 17.059px;
          justify-content: center;
          align-items: center;
          color: #e2e9ec;
          text-align: center;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          border: none;
          outline: none;

          &:hover {
            background: #1e7e61 !important;
            transition: 0.3s;
          }

          &:focus {
            background: #1e7e61 !important;
            box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5) !important;
            transition: 0.3s;
          }

          &.disabled {
            border-radius: 4px;
            background: rgba(50, 152, 121, 0.2);
            color: rgba(2, 23, 29, 0.3);

            svg {
              opacity: 0.2;
            }
          }
        }

        .parentcontent {
          margin: 0px 0px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          p {
            color: var(--Primary-Text-Color, #02171d);
            text-align: center;
            font-family: "f37";
            font-size: 16px;
            font-style: normal;
            line-height: 120%;
            margin-top: 11px;
          }

          .forspan {
            margin-top: 0px !important;
          }

          .purchasedpara {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            width: 460px;

            span {
              color: #000;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
            }
          }
        }

        .ok {
          border-radius: 4px;
          background: var(--Primary-Green, #329879);
          display: flex;
          width: 100%;
          padding: 16px 17.059px;
          justify-content: center;
          align-items: center;
          color: #e2e9ec;
          text-align: center;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          border: none;
          outline: none;

          &:hover {
            background: #1e7e61 !important;
            transition: 0.3s;
          }

          &:focus {
            background: #1e7e61 !important;
            box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5) !important;
            transition: 0.3s;
          }

          &.disabled {
            border-radius: 4px;
            background: rgba(50, 152, 121, 0.2);
            color: rgba(2, 23, 29, 0.3);

            svg {
              opacity: 0.2;
            }
          }
        }

        .doppara {
          color: var(--Secondary-Text-Color, #77868b);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
        }

        .dopparent {
          margin: 18px 0px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          flex-direction: column;

          .material-textfield {
            margin: 0 !important;
          }

          .specific-value-text {
            color: var(--Secondary-Text-Color, #77868b);
            font-family: "hmedium";
            font-size: 12px;
            font-style: normal;
            line-height: 100%;
            margin-top: 8px;
            margin-bottom: 25px;

            span {
              color: var(--Primary-Text-Color, #02171d);
            }
          }

          .cardsss {
            border-radius: 10px;
            border: 1px solid #eee;
            padding: 16px 12px;
            background: #fff;
            max-width: 96px;
            height: 46px;

            p {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
            }
          }
        }

        .onlymodbtnns {
          display: flex;
          align-items: center;
          gap: 10px;
          align-self: stretch;

          .claimbtnnew {
            border-radius: 15px;
            background: #329879;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
            padding: 18px 25px;
            color: #000;
            text-align: center;
            font-family: "Space Grotesk";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
            border: none;
          }
        }

        .twice-field {
          width: 100%;
        }

        .option-field {
          position: relative;

          a {
            position: absolute;
            right: 18px;
            top: 50px;
            color: var(--Primary-Text-Color, #02171d);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
          }
        }

        .twice-box {
          border-radius: 4px;
          background: var(--Text-Field-Background, #d1dbdf);
          padding: 8px 20px;
        }

        .parentdoptext {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 0;
          border-bottom: 1px solid #c0cbcf;
          gap: 30px;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          h6 {
            color: var(--Secondary-Text-Color, #77868b);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
          }

          p {
            color: var(--Primary-Text-Color, #02171d);
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
          }
        }

        .twicebtns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          margin-top: 35px;

          .back {
            border-radius: 4px;
            background: var(--Text-Field-Background, #d1dbdf);
            border: none;
            padding: 16px 17.059px;
            color: var(--Primary-Text-Color, #02171d);
            text-align: center;
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            width: 100% !important;
          }

          .buy {
            border-radius: 4px;
            background: var(--Primary-Green, #329879);
            display: flex;
            width: 100% !important;
            padding: 16px 17.059px;
            justify-content: center;
            align-items: center;
            color: #e2e9ec;
            text-align: center;
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
            border: none;
            outline: none;

            &:hover {
              background: #1e7e61 !important;
              transition: 0.3s;
            }

            &:focus {
              background: #1e7e61 !important;
              box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5) !important;
              transition: 0.3s;
            }

            &.disabled {
              border-radius: 4px;
              background: rgba(50, 152, 121, 0.2);
              color: rgba(2, 23, 29, 0.3);

              svg {
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .mainclaims .parent {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .mainclaims .main-card .table-responsive {
    display: block !important;
  }

  .mainclaims .parent .right {
    flex-direction: column;
    width: 100%;
  }

  .mainclaims .parent .right .innerright {
    width: 100%;
  }

  .mainclaims .parent .right .innerright input {
    width: 100%;
    max-width: 100%;
  }

  .mainclaims .parent .right .dropbtn .dropdown .dropdown-toggle {
    width: 100% !important;
    max-width: 100%;
  }

  .mainclaims .parent .right .dropbtn {
    width: 100% !important;
    max-width: 100%;
  }

  .mainclaims .main-card .footer-content .left-f {
    display: none !important;
  }

  .mainclaims .main-card .footer-content {
    justify-content: center;
  }
}

.claimedbtn {
  border-radius: 4px;
  background: var(--Primary-Green, #329879);
  display: flex;
  width: 140px;
  padding: 15px 26px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-family: "hmedium";
  line-height: 100%;
  border: none;
  display: block;
  margin: 0 auto;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.claim-seperate {
  border-radius: 4px;
  background: var(--Primary-Green, #329879);
  display: flex;
  width: 81px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  color: #e2e9ec;
  font-family: "hmedium";
  font-size: 12px;
  font-style: normal;
  border: none;
  outline: none;
  line-height: 100%;
  text-transform: capitalize;

  &:hover {
    background: #1e7e61 !important;
    transition: 0.3s;
  }

  &:focus {
    background: #1e7e61 !important;
    box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5) !important;
    transition: 0.3s;
  }

  &.disabled {
    border-radius: 4px;
    background: rgba(50, 152, 121, 0.2);
    color: rgba(2, 23, 29, 0.3);

    svg {
      opacity: 0.2;
    }
  }
}

.green111 {
  color: var(--Green, #329879) !important;
  font-family: "hmedium" !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 21px !important;
  letter-spacing: 0.28px !important;
}

.yellow111 {
  color: var(--Orange, #e88f35) !important;
  font-family: "hmedium" !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 21px !important;
  letter-spacing: 0.28px !important;
}

.red111 {
  color: #fc4f52 !important;
  font-family: "hmedium" !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 21px !important;
  letter-spacing: 0.28px !important;
}

// .claimmodal .modal-dialog .modal-content .modal-body .parentdoptext {
//   flex-direction: column;
// }

.claimmodal .modal-dialog .modal-content .modal-body .parentdoptext .textt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.set-custom-width-claim {
  padding-left: 0 !important;

  .modal-dialog {
    max-width: 606px !important;

    .para {
      min-height: 88px;
    }
  }

  .twicebtns {
    justify-content: space-between !important;

    button {
      width: 48% !important;
    }
  }
}

@media (max-width: 600px) {
  .claimmodal .modal-dialog .modal-content .modal-body .twice-content {
    flex-direction: column;
  }

  .claimmodal .modal-dialog .modal-content .modal-body .para {
    min-height: auto;
  }

  .claimmodal
    .modal-dialog
    .modal-content
    .modal-body
    .modalcard
    .cardparent
    h6 {
    text-align: center;
  }

  .claimmodal .modal-dialog .modal-content .modal-body .parentdoptext p {
    text-align: right;
  }

  .mainclaims .parent .left h2 {
    text-align: start;
    width: 100%;
  }
}

#custom-tooltip-claim {
  .tooltip-inner {
    border-radius: 12px;
    border: 1px solid #242424;
    background: #000;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.1);
    padding: 17px;
    max-width: 329px !important;

    h6 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 8px;
      text-align: start;
    }

    p {
      color: #cecece;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
    }
  }
}

#custom-tooltip-prices {
  .tooltip-inner {
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    background: #fff;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
    width: 208px !important;
    padding: 0;
    overflow: hidden;

    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #797977;
      background: #fff;
      padding: 10px 18px;

      p {
        color: #777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      h6 {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .tooltip-arrow {
    display: none !important;
  }
}

.claim-detail-table {
  border-radius: 10px;
  background: #f8f8f8;
  padding: 14px;
  margin-top: 15px;

  .main-heading {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
  }

  .table-responsive {
    border-radius: 12px;
    border: 1px solid #e6e6e6;

    th {
      padding: 10px 20px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      vertical-align: middle;
    }

    td {
      padding: 10px 20px;
      vertical-align: middle;
    }
  }

  .copy-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.28px;
    }
  }

  .twice-text {
    h6 {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.28px;
      margin-bottom: 5px;
    }

    p {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.28px;
    }
  }

  .insurance-text {
    font-size: 14px;
    font-style: normal;
    font-family: "hbold";
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  .orange-text {
    color: #fc4f52 !important;
  }

  .green-text {
    color: #329879 !important;
  }
}

.widthset {
  .modal-dialog {
    max-width: 480px;
  }
}

.btn-claimallpending {
  border-radius: 4px;
  background: #329879;
  background: var(--Primary-Green, #329879);
  border: none;
  padding: 15px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  outline: none;
  color: #e2e9ec;
  color: var(--Button-Text, #e2e9ec);
  font-size: 14px;
  font-style: normal;
  font-family: "hregular";
  line-height: 100%;
  transition: 0.3s linear;
}
