.main-staking {
  padding-bottom: 100px;

  .upperbanner {
    background-image: url("../../../src/assets/backgroundbanner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 413px;
    padding-top: 60px;

    .staketoppara {
      color: var(--Primary-Green, #329879);
      font-family: "bl";
      font-size: 32px;
      font-style: normal;
      line-height: 140%;
      margin-bottom: 20px;
    }

    .stakemainhead {
      max-width: 696px;
      width: 100%;
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 55px;
      font-style: normal;
      line-height: 100%;
      text-transform: uppercase;

      span {
        font-family: "bl";
      }
    }

    p {
      color: var(--Primary-Text-Color, #02171d);
      font-size: 21px;
      font-style: normal;
      font-family: "hregular";
      line-height: 140%;
      max-width: 696px;
      width: 100%;
      margin-top: 20px;
      padding-left: 20px;
      border-left: 3px solid #02171d;
    }
  }

  .lowerbanner {
    .lowerinnerbanner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 12px;

      .lowerleft {
        max-width: 459px;
        width: 100%;
        padding: 25px;
        border-radius: 12px;
        background: var(--Black, #000);
        position: relative;
        overflow: hidden;

        .lowerleftshade {
          width: 310px;
          height: 215px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0%);
          bottom: -258px;
          border-radius: 310px;
          background: #4cead7;
          filter: blur(105px);
        }

        .totalpara {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        .totalgemsmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          padding-bottom: 25px;
          margin-bottom: 25px;

          .gemslogoimg {
            width: 34px;
            height: 34px;
          }

          .totalgemspara {
            color: #4cead7;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
          }
        }

        .stakebalance {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;

          .stakepara {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.24px;
            text-transform: uppercase;
          }

          .balancepara {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }

        .amountinput {
          border-radius: 10px;
          border: 1.5px solid rgba(255, 255, 255, 0.15);
          background: rgba(255, 255, 255, 0.02);
          padding: 20px 16px;
          outline: none;
          width: 100%;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          margin-bottom: 25px;

          &::placeholder {
            color: #8d8d8d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }
        }

        .stakingperiodmain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 25px;

          .stakingperiodpara {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }

          .stakingyearpara {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.24px;
          }
        }

        .approvebtn {
          display: flex;
          padding: 18px 25px;
          justify-content: center;
          align-items: center;
          color: var(--Black, #000);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;
          border-radius: 15px;
          background: #329879;
          box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
          outline: none;
          border: none;
          width: 100%;
          z-index: 9999;
        }
      }

      .lowerright {
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        background: #fff;
        width: 100%;

        .lowerrightpara {
          color: var(--Black, #000);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.36px;
          text-transform: uppercase;
          padding: 18px 20px;
          border-bottom: 1px solid #e6e6e6;
          background: #fff;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
      }
    }
  }

  .newboxes-twice {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .single-box {
      background: var(--Background-Color, #e2e9ec);
      backdrop-filter: blur(20px);
      border-right: 1px solid var(--Stroke, #c0cbcf);
      padding: 25px;
      position: relative;
      z-index: 9;
      min-height: 630px;

      &:last-child {
        border-right: 1px solid transparent;
      }

      .upper-content {
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);

        h6 {
          color: var(--Primary-Text-Color, #02171D);
          font-family: "f37";
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; 
          text-transform: capitalize;
          letter-spacing: -0.22px;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        h4 {
          margin: 10px 0;
          color: #77868b;
          font-size: 40px;
          font-style: normal;
          font-family: "f37";
          line-height: 120%;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          .setpara {
            p {
              color: var(--Secondary-Text-Color, #77868b);
              font-size: 18px !important;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;

              &:last-child {
                color: var(--Secondary-Text-Color, #77868b);
                font-size: 12px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
              }
            }
          }

          span {
            color: #02171d;
            font-size: 40px;
            font-style: normal;
            font-family: "f37";
            line-height: 120%;
            letter-spacing: 0.8px;
            text-transform: uppercase;
          }
        }

        p {
          color: #02171d;
          font-size: 18px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          letter-spacing: 0.36px;
        }
      }

      .bottom-content {
        padding: 7px 20px;
        border-radius: 4px;
        background: var(--Text-Field-Background, #d1dbdf);
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        .inner-single {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 13px 0;
          border-bottom: 1px solid #c0cbcf;

          .text {
            display: flex;
            gap: 10px;
            align-items: center;

            p {
              color: #02171d;
              font-size: 14px;
              font-style: normal;
              font-family: "hregular";
              line-height: 120%;
              letter-spacing: -0.14px;

              &.cutting {
                text-decoration: line-through !important;
              }
            }
          }

          h6 {
            color: #02171d;
            font-size: 16px;
            font-style: normal;
            font-family: "hbold";
            line-height: 120%;
            letter-spacing: -0.16px;
          }
        }
      }

      .twice-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 39px;

        button {
          padding: 18px 25px;
          color: var(--Black, #000);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: capitalize;
          width: 49%;
          width: 100%;
          display: block;
          margin: 0 auto;

      
        }

        .withdraw-gems {
          border-radius: 4px;
          background: var(--Text-Field-Background, #d1dbdf);
          color: #02171d;
          border: none;
        }

        .upgrade-gems {
          border-radius: 4px;
          background: #329879;
          color: #e2e9ec;
          border: none;
       
        }
        .upgrade-gems:disabled{
          color: var(--Disable-Button-Text, #8EA7A4);
background: var(--Disable-Button-BG, #C8E5E0);
        }
      }

      .withdrawbox {
        border-radius: 4px;
        border: 1px solid #e88f35;
        background: #f3f1ec;
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-top: 30px;

        .withdrawhead {
          color: #e88f35;
          font-family: "f37";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.36px;
        }

        .withdrawpara {
          color: #e88f35;
          font-family: "hregular";
          font-size: 18px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: 0.36px;
        }

        .withdrawbtn {
          border-radius: 6px;
          background: #e88f35;
          display: flex;
          padding: 18px 22px;
          justify-content: center;
          align-items: center;
          color: var(--Button-Text, #e2e9ec);
          font-family: "hmedium";
          border: none;
          font-size: 14px;
          font-style: normal;
          line-height: 100%;
          width: 100%;
        }
      }

      .withdrawaccordion {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        padding: 0 !important;
        margin: 30px 0;

        .accordion-item {
          outline: none !important;
          box-shadow: none !important;
          margin: 0 !important;
          padding: 20px;
          border-radius: 4px;
          border: 1px solid #e88f35;
          background: #f3f1ec;

          .accordion-header {
            background: transparent !important;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            padding: 0 !important;
            margin: 0 !important;

            .accordion-button {
              background: transparent !important;
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              border-radius: 0 !important;
              padding: 0 !important;
              margin: 0 !important;
              display: flex;
              align-items: center;
              gap: 10px;
              color: #e88f35;
              font-family: "f37";
              font-size: 18px;
              font-style: normal;
              line-height: 120%;
              letter-spacing: 0.36px;

              &:after {
                background: url("../../assets/arrowacc.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 24px;
                height: 24px;
                padding: 0;
                outline: none;
                box-shadow: none;
                border: none;
              }
            }
          }

          .accordion-body {
            background: transparent !important;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            padding: 0;
            margin: 0 !important;
            padding-top: 16px !important;

            .accpara {
              color: #e88f35;
              font-family: "hregular";
              font-size: 16px;
              font-style: normal;
              line-height: 120%;
              letter-spacing: 0.32px;
              margin-bottom: 30px;

              .bolder {
                font-family: "hsemibold";
              }
            }

            .lastparaacc {
              color: #e88f35;
              text-align: center;
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
              letter-spacing: 0.28px;
              text-transform: capitalize;
              margin-top: 16px;
            }

            .dullbtn {
              border-radius: 6px;
              background: #efcaa3;
              display: flex;
              padding: 18px 22px;
              justify-content: center;
              align-items: center;
              color: var(--Button-Text, #e2e9ec);
              font-family: "hmedium";
              border: none;
              width: 100%;
              font-size: 14px;
              font-style: normal;
              line-height: 100%;
              margin-top: 16px;
            }

            .withdrawbtn {
              border-radius: 6px;
              background: #e88f35;
              display: flex;
              padding: 18px 22px;
              justify-content: center;
              align-items: center;
              color: var(--Button-Text, #e2e9ec);
              font-family: "hmedium";
              border: none;
              width: 100%;
              font-size: 14px;
              font-style: normal;
              line-height: 100%;
              margin-top: 16px;
            }
          }
        }
      }
    }

    .bottom-para {
      color: var(--Black, #000);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.14px;
      max-width: 441px;
      width: 100%;
      margin: 0 auto;
      margin-top: 13px;
    }

    .btn-plan {
      border-radius: 6px;
      border: 1px solid var(--Stroke, #c0cbcf);
      background: var(--Background-Color, #e2e9ec);
      padding: 18px 25px;
      color: #329879;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 100%;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 39px;
      pointer-events: none;

      &.activee {
        border-radius: 6px;
        background: var(--Primary-Green, #329879);
        color: #e2e9ec;
        pointer-events: visible;
      }

      h6 {
        color: var(--Primary-Green, #329879);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        margin-bottom: 3px;
      }

      p {
        color: var(--Primary-Text-Color, #02171d);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
        text-transform: uppercase;
      }
    }
  }
}

.stakingmodal {
  .modal-dialog {
    max-width: 554px;

    .modal-content {
      border-radius: 4px;
      background: #e2e9ec;

      .modal-header {
        padding: 25px;
        border: none;
        border-bottom: 1px solid #797977;

        .modal-title {
          color: #02171d;
          font-family: "f37";
          font-size: 20px;
          font-style: normal;
          line-height: 120%;
        }

        .btn-close {
          background: url("../../../src/assets/btnclose.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          opacity: unset !important;
          border: none;
          outline: none;
          box-shadow: none;
          padding: 0;
          margin: 0;
        }
      }

      .modal-body {
        padding: 25px;

        .addedmodal {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .successhead {
            color: #02171d;
            text-align: center;
            font-family: "f37";
            font-size: 16px;
            font-style: normal;
            line-height: 120%;
            margin-top: 20px;
            margin-bottom: 17px;
          }

          .succesaspara {
            color: #77868b;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-family: "hmedium";
            line-height: 140%;
            margin-bottom: 20px;
          }
        }

        .stakingmodal {
          .stakingamoutmain {
            width: 100%;
            display: flex;
            padding: 26.5px 37px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            background: var(--Black, #000);
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;

            .whiteshade {
              border-radius: 430px;
              background: #4cead7;
              filter: blur(105px);
              width: 430px;
              height: 215px;
              position: absolute;
              bottom: -190px;
              left: 50%;
              transform: translate(-50%, 50%);
            }

            .stakingamountpara {
              color: #fff;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              margin-bottom: 5px;
              letter-spacing: 0.24px;
              text-transform: uppercase;
              text-align: center;
              z-index: 9;
              position: relative;
            }

            .stakingamounthead {
              color: #329879;
              font-size: 28px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.28px;
              text-transform: uppercase;
              text-align: center;
              width: 100%;
              z-index: 9;
              position: relative;
            }
          }

          .stakingdetails {
            border-radius: 12px;
            border: 1px solid #e6e6e6;
            background: #fff;
            margin-bottom: 20px;

            .stakingdetailinner {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 14px 18px;
              border-bottom: 1px solid #797977;

              &:last-child {
                border-bottom: none;
              }

              .stakingdetailpara {
                color: #777;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .stakingdetailhead {
                color: var(--Black, #000);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }

          .stakingbtns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .cancelbtn {
              border-radius: 4px;
              background: var(--Text-Field-Background, #d1dbdf);
              border: none;
              display: flex;
              padding: 18px 25px;
              justify-content: center;
              align-items: center;
              color: var(--Black, #02171d);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 100%;
              width: 100%;
              text-transform: uppercase;
            }

            .confirmbtn {
              display: flex;
              padding: 18px 25px;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              background: #329879;
              border: none;
              color: var(--Black, #e2e9ec);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 100%;
              text-transform: uppercase;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .main-staking .lowerbanner .lowerinnerbanner {
    flex-direction: column;
  }

  .main-staking .lowerbanner .lowerinnerbanner .lowerleft {
    max-width: 100%;
  }

  .main-staking .upperbanner .stakemainhead {
    font-size: 30px;
  }

  .main-staking .upperbanner .staketoppara {
    font-size: 16px;
  }

  .stakingmodal
    .modal-dialog
    .modal-content
    .modal-body
    .stakingmodal
    .stakingbtns {
    flex-direction: column;
  }

  .main-staking .newboxes-twice {
    grid-template-columns: auto;
  }

  .main-staking .newboxes-twice .single-box {
    height: auto;
  }

  .main-staking .lowerbanner {
    margin-top: -90px;
  }

  .main-staking .newboxes-twice .single-box .twice-btns {
    flex-direction: column;
    gap: 15px;
  }
}

.premiumstake-modal {
  .inner-content {
    position: relative;
    z-index: 9;
    overflow: hidden;
    padding-top: 43px;
    padding-bottom: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    .modalbg-stake {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;
      width: 100%;
      height: 100%;
    }

    h5 {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "f37";
      line-height: 120%;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 6px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 140%;
    }
  }
}

.cdhbdybydbcy {
  color: #fc4f52 !important;
  text-align: center !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  letter-spacing: 0.24px !important;
  text-transform: uppercase !important;
  border-radius: 6px;
  background: rgba(255, 74, 74, 0.16);
  padding: 6px 10px;
}

@media (max-width: 1450px) {
  .main-staking .theme-container {
    max-width: 1200px !important;
  }
}

@media (max-width: 600px) {
  .main-staking .newboxes-twice .single-box {
    border: none;
    padding: 0;
  }

  .main-staking
    .newboxes-twice
    .single-box
    .withdrawaccordion
    .accordion-item
    .accordion-header
    .accordion-button {
    font-size: 14px;
  }

  .main-staking
    .newboxes-twice
    .single-box
    .withdrawaccordion
    .accordion-item
    .accordion-body
    .accpara {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
