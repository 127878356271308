.leaderprofile {
    padding: 30px 0px;
    min-height: 100vh;

    .mainleaderprofile {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .leaderprofileleft {
            max-width: 311px;
            width: 100%;

            .leaderprofileleftup {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                background: #FFF;
                padding: 25px 20px;
                margin-bottom: 15px;

                .leaderprofileleftmainimg {
                    display: flex;
                    width: 132px;
                    height: 132px;
                    padding: 4.4px;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 300px;
                    border: 1px solid #E6E6E6;
                    background: #FFF;

                    .leaderprofileleftinnerimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 300px;
                    }

                }

                .leaderprofilehead {
                    color: #000;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    margin-top: 20px;
                    letter-spacing: 0.4px;
                    text-transform: uppercase;
                }

                .leaderprofilepara {
                    color: #329879;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin: 4px 0px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                }

                .activespan {
                    border-radius: 40px;
                    background: rgba(34, 193, 84, 0.10);
                    display: flex;
                    padding: 5px 19px;
                    justify-content: center;
                    align-items: center;
                    color: #329879;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    margin-bottom: 14px;
                }

                .sendinvitebtnmain {
                    width: 100%;
                    border-bottom: 1px solid #EDEDED;
                    padding-bottom: 15px;
                    margin-bottom: 30px;
                }

                .leaderprofiledetail {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;

                    .leaderprofiledetailinner {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        .leaderprofileparainner {
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            text-transform: uppercase;
                            opacity: 0.6;
                        }

                        .leaderprofileheadinner {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                        }
                    }
                }
            }

            .leaderprofileleftlow {
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                padding: 20px;
                background: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .leaderprofileleftlowdetail {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;

                    .leaderprofileleftlowdetailpara {
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        opacity: 0.6;
                        text-transform: uppercase;
                    }

                    .leaderprofileleftlowdetailhead {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                    }
                }
            }
        }

        .bio {
            .maincard {
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                padding: 20px;
                width: 100%;
                background: #FFF;
                .main-head {
                    h2 {
                        color: #000;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        margin-bottom: 18px;
                    }
                    p {
                        color: #323232;
                        margin-bottom: 10px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                    .project {
                        color: #000;
                        margin-top: 23px;
                        margin-bottom: 18px;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        /* 24px */
                        text-transform: uppercase;
                    }
                }
                .parentcard {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;
                    .innercard {
                        border-radius: 12px;
                        border: 1px solid #F8F8F8;
                        padding: 20px;
                        width: 100%;
                        background: #FFF;
                        .innercontent {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 12px;
                            .left {
                                width: 55px;
                                height: 55px;
                                overflow: hidden;
                                object-fit: cover;
                                border-radius: 120px;
                                object-position: center;
                                .picc {
                                    border-radius: 120px;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                            .right {
                                h3 {
                                    color: #000;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 120%;
                                    letter-spacing: -0.18px;
                                }
                                p {
                                    color: #000;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 120%;
                                    margin-bottom: 0px;
                                }
                            }
                        }
                        .brdr {
                            background: #F0F0F0;
                            width: 100%;
                            height: 1px;
                            margin: 19px 0px;
                        }
                        .parentcardsss {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 8px;
                            h3 {
                                color: #000;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 120%;
                                text-transform: uppercase;
                                margin-bottom: 0px;
                            }
                            p {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 120%;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }


        .acheivmentmain {
            width: 100%;

            .acheivmentmainhead {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 18px;
                text-transform: uppercase;
                margin-top: 23px;
            }

            .acheivmentmaincardsdiv {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 18px;

                .acheivementcard {
                    border-radius: 12px;
                    background: #000;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    height: 279px;
                    width: 100%;
                    padding-top: 24px;
                    position: relative;
                    overflow: hidden;

                    .shade {
                        border-radius: 292px;
                        background: #329879;
                        filter: blur(105px);
                        width: 292px;
                        height: 215px;
                        position: absolute;
                        bottom: -197px;
                        left: 0;
                        right: 0;
                    }

                    .lightblue{
                        background: #0FF !important;
                    }
                    
                    .golden{
                        background: #ADFF00 !important;
                    }

                    .acheivementcardimg {
                        border-radius: 140px;
                        border: 1px solid #E6E6E6;
                        background: #FC4F52;
                        display: flex;
                        width: 55px;
                        height: 55px;
                        justify-content: center;
                        align-items: center;
                        object-fit: cover;
                        object-position: center;

                        .acheivementcardinnerimg {
                            border-radius: 140px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .acheivementcardhead {
                        color: #FFF;
                        margin-top: 12px;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.18px;
                        margin-bottom: 22px;
                    }

                    .acheivementcardparablue {
                        color: #329879;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: 0.24px;
                        text-transform: uppercase;
                    }

                    .acheivementcardheadblue {
                        color: #329879;
                        text-align: center;
                        font-size: 45px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.45px;
                        margin: 6px 0px;
                    }

                    .acheivementcardpara {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: 0.28px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media(max-width:1170px){
    .leaderprofile .mainleaderprofile{
        flex-direction: column;
    }
    .leaderprofile .mainleaderprofile .leaderprofileleft{
        max-width: 100%;
    }
}

@media(max-width:992px){
    .leaderprofile .mainleaderprofile .acheivmentmain .acheivmentmaincardsdiv{
        grid-template-columns: 1fr;
    }
    .bio .maincard .parentcard{
        flex-direction: column;
    }
}

