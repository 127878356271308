.all-transaction {
  padding-top: 43px;
  padding-bottom: 43px;

  .upper-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #000;
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .right-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      max-width: 555px;
      width: 100%;

      .option-field {
        position: relative;
        max-width: 427px;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1.5px solid #D0DEE4;
        background: var(--Text-Field-Background, #D1DBDF);

        .inputdrop {
          background: transparent;
          background-color: transparent;

          .btn {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: 100%;
            background: transparent;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border: none;
            height: 49px;
            border-right: 1px solid #e6e6e6;
            border-radius: 0px;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            border-radius: 4px;
            border: 1.5px solid #D0DEE4;
            background: var(--Text-Field-Background, #D1DBDF);
            padding: 0px;
            margin: 0px;
            max-height: 200px;
            overflow: auto;

            &::-webkit-scrollbar {
              display: none !important;
            }

            .drop {
              padding: 10px 15px;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-family: "hregular";
              line-height: 100%;
              overflow: hidden;
              border-bottom: 1px solid #e6e6e6;

              &:last-child {
                border-bottom: none;
              }

            }
          }
        }

        input {
          border: none;
          background: transparent;
          outline: none;
          padding: 15px 20px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;

          &::placeholder {
            color: #77868B;
          }
        }

        .search-icon {
          position: absolute;
          top: 13px;
          right: 15px;
        }
      }

      .filter-btnnn {
        border-radius: 4px;
        background: var(--Text-Field-Background, #D1DBDF);
        border: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
      }
    }
  }

  .bottom-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    margin-top: 25px;

    .inner-single {
      p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 13px;
      }

      .multi-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
      }

      h6 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: #000;
        padding: 15px 10px;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        height: 30px;
      }
    }
  }
}

// filter offcanvas scss here...............................

.offcanvas-backdrop {
  background: var(--Pop-Up-Background-Color, rgba(167, 182, 187, 0.65));
  backdrop-filter: blur(7.5px);
  opacity: 1 !important;
}

.filter-offcanvas {
  max-width: 512px !important;
  width: 100% !important;
  background: #E2E9EC;

  .offcanvas-header {
    border-bottom: 1px solid #797977;
    background: #E2E9EC;
    padding: 24px 20px;
    margin-bottom: 24px;

    .offcanvas-title {
      color: #02171D;
      font-family: "f37";
      font-size: 20px;
      font-style: normal;
      line-height: 120%;
      margin: 0;
    }

    .btn-close {
      background: url("../../assets/close-circle.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
      padding: 0;
      box-shadow: none !important;
    }
  }

  .custom-tab-bar {
    width: 100%;
    max-width: 100% !important;
  }

  .offcanvas-body {
    padding: 24px 20px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .round-dropdown {
    margin-top: 15px;
    width: 100%;

    .dropdown-toggle {
      border-radius: 4px;
      background: var(--Text-Field-Background, #D1DBDF);
      padding: 15px;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      padding: 0;
      width: 100%;
      border-radius: 4px;
      background: var(--Text-Field-Background, #D1DBDF);

      a {
        border-bottom: 1px solid #797977;
        padding: 12px 18px;
      }
    }
  }

  .role-dropdown {
    .dropdown-menu {
      a {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .ifroundselect {
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    h6 {
      border-radius: 4px;
      background: var(--Light-Mode-Primary-Text-Color, #02171D);
      height: 30px;
      padding: 0px 10px;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-family: "hregular";
      line-height: 120%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      min-width: 60px;
      height: 30px;
    }
  }

  .bottom-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
      flex: 0 0 48%;
      padding: 15px 40px;
    }

    .btn-clearall {
      border-radius: 4px;
      background: var(--Text-Field-Background, #D1DBDF);
      border: none;
      color: #02171D;
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      text-transform: capitalize;
    }

    .btn-applyy {
      border-radius: 4px;
      background: #329879;
      border: none;
      color: #E2E9EC;
      border: none;
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      text-transform: capitalize;
      transition: 0.3s linear;

      &:hover {
        border-radius: 4px;
        background: #1E7E61;
      }

      &:active {
        border-radius: 4px;
        background: #1E7E61;
        box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.50);
      }
    }
  }

  .uppper-area {
    width: 100%;
  }
}

.dropdownbysearch {
  width: 100%;
  margin-top: 15px;

  .se-custom-flex-gap {
    width: 100%;
    justify-content: space-between !important;
  }

  .dropdown-toggle {
    border-radius: 4px;
    background: var(--Text-Field-Background, #D1DBDF);
    border: none;
    padding: 15px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 4px;
    background: var(--Text-Field-Background, #D1DBDF);
    width: 100%;
    border-radius: 0;
    padding: 0;

    a {
      color: #000 !important;
    }
  }
}

.newuichangesonhead {
  .rightside-contentnew {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    max-width: 543px;
    width: 100%;

    .option-field {
      position: relative;
      max-width: 385px;
      width: 100%;

      input {
        border: 1px solid #fff;
        background: #000;
        padding: 15px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        width: 100%;

        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .search-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
      }
    }
  }
}

// new ui scss here..................................

.custom-tab-bar {
  border-radius: 4px;
  background: var(--Tab-Background, #D9E2E5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 331px;
  width: 100%;
  position: relative;
  height: 46px;

  a {
    border-radius: 4px;
    background: var(--Tab-Background, #D9E2E5);
    padding: 6px 11px;
    color: #a2a4a6;
    font-size: 15px;
    font-style: normal;
    font-family: "f37";
    line-height: 100%;
    text-align: center;
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      color: #329879;
      padding: 11px;
      border-radius: 4px;
      background: rgba(117, 240, 204, 0.30);
    }
  }
}

.cal {
  position: relative;

  .rmdp-wrapper {
    position: absolute;
    top: 30px;
    left: unset;
    right: 0;
  }
}

.drop-in-search-sticky {
  position: sticky;
  top: -5px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  z-index: 9;

  input {
    border: none;
    background: var(--Text-Field-Background, #D1DBDF);
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 18px;
    width: 100%;
    padding-right: 50px;

    &::placeholder {
      color: #96A6AC;
    }
  }

  .search-icon {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

@media (max-width: 991px) {
  .all-transaction .upper-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
  }

  .all-transaction .upper-heading .right-content {
    flex-direction: column;
  }

  .all-transaction .upper-heading .right-content .filter-btnnn {
    width: 100%;
  }

  .all-transaction .bottom-content {
    grid-template-columns: repeat(1, 1fr);
  }
}