.maindashboard {
  padding-top: 25px;
  padding-bottom: 40px;
  min-height: 100vh;

  // .twice-elem {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     gap: 12px;
  //     margin-bottom: 12px;

  //     .dashboarddetailmain{
  //         max-width: 70%;
  //         width: 100%;
  //     }

  //     .staking-box {
  //         flex: 1;
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: flex-start;
  //         gap: 30px;
  //         position: relative;
  //         z-index: 9;
  //         padding: 25px;
  //         border-radius: 12px;

  //         .stake-bg {
  //             position: absolute;
  //             top: 0;
  //             left: 0;
  //             width: 100%;
  //             height: 100%;
  //             z-index: -1;
  //             pointer-events: none;
  //             border-radius: 12px;
  //             object-fit: cover;
  //         }

  //         .text {
  //             p {
  //                 color: #FFF;
  //                 font-size: 14px;
  //                 font-style: normal;
  //                 font-weight: 500;
  //                 line-height: 120%;
  //                 letter-spacing: 0.28px;
  //                 text-transform: uppercase;
  //                 margin-bottom: 12px;
  //             }

  //             h6 {
  //                 color: #4CEAD7;
  //                 font-size: 22px;
  //                 font-style: normal;
  //                 font-weight: 700;
  //                 line-height: 120%;
  //                 letter-spacing: -0.22px;
  //                 text-transform: uppercase;
  //                 display: flex;
  //                 justify-content: flex-start;
  //                 align-items: center;
  //                 gap: 8px;

  //                 .btn-stake {
  //                     border-radius: 8px;
  //                     background: rgba(255, 255, 255, 0.30);
  //                     box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.07) inset;
  //                     padding: 8px 10px;
  //                     color: #FFF;
  //                     font-size: 10px;
  //                     font-style: normal;
  //                     font-weight: 500;
  //                     line-height: 100%;
  //                     text-transform: uppercase;
  //                 }

  //             }
  //         }
  //     }
  // }

  .twice-new-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    height: 290px;
    margin-bottom: 36px;

    .left-side-content {
      max-width: 949px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }

    .staking-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      position: relative;
      z-index: 9;
      padding: 16px;
      border-radius: 4px;
      height: 100%;

      .stake-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        border-radius: 4px;
        object-fit: cover;
      }

      .upper-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 36px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      .line {
        width: 100%;
        height: 1px;
        background: #ffffff33;
      }

      .text {
        p {
          color: #77868b;
          font-size: 14px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
          letter-spacing: 0.28px;
          text-transform: uppercase;
          margin-bottom: 12px;
        }

        h6 {
          color: #fff;
          font-size: 22px;
          font-style: normal;
          font-family: "f37";
          line-height: 120%;
          letter-spacing: -0.22px;
          text-transform: uppercase;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .btn-stake {
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.07) inset;
            padding: 8px 10px;
            color: #fff;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
          }
        }
      }

      .bottom-content {
        width: 100%;

        .newpremiumpara {
          line-height: 19.2px;
          font-weight: 400;
          text-align: center;
          color: #fff;
          font-size: 16px;
          margin-bottom: 10px;
        }

        p {
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          letter-spacing: 0.32px;
          margin-bottom: 10px;
        }

        a {
          border-radius: 4px;
          background: #30c799;
          padding: 18px 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: var(--Black, #01141a);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 100%;
          text-transform: uppercase;
          transition: 0.3s linear;

          &:hover {
            border-radius: 4px;
            background: #1e7e61;
          }

          &:active {
            border-radius: 4px;
            background: #1e7e61;
            box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5);
          }
        }
      }
    }

    .staking-box-premium {
      .mid-div {
        .para {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: 0.32px;
          margin-bottom: 20px;
        }

        .feature-btn {
          color: #329879;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.28px;
        }
      }

      .twice-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;

        .item-box {
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.07);
          background: rgba(0, 0, 0, 0.3);
          backdrop-filter: blur(17px);
          padding: 14px 12px;

          p {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0.24px;
            text-transform: uppercase;
            margin-bottom: 9px;
          }

          h6 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 6px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.18px;
            text-transform: uppercase;

            &.noPack {
              color: #9b9b9b;
            }

            &.emerald {
              color: #79ca29;
            }

            &.sapphire {
              color: #5263ff;
            }

            &.reddiamond {
              color: #ca2d2d;
            }

            &.diamond {
              color: #c3c0c0;
            }

            &.ruby {
              color: rgb(147, 15, 15);
            }
          }
        }
      }

      .bottom-content {
        width: 100%;

        p {
          color: #fff;
          text-align: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: 0.32px;
          margin-bottom: 0;
        }

        a {
          border-radius: 15px;
          background: #329879;
          box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
          padding: 18px 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: var(--Black, #000);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;
        }
      }
    }
  }

  .dashboarddetailmain {
    display: flex;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 2px solid #797977;
    background: #fff;

    .dashboarddetailleft {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .detailcard {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        margin-right: 25px;
        padding-right: 25px;
        border-right: 1px solid #ffe6ff;

        .detailimg {
          border-radius: 140px;
          border: 1px solid #e6e6e6;
          display: flex;
          width: 55px;
          height: 55px;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          object-position: center;

          .detailinnerimg {
            border-radius: 140px;
            border: 1px solid #e6e6e6;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .detailcardtexts {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 4px;
          flex-direction: column;

          .detailcardhead {
            color: var(--Black, #000);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.18px;
          }

          .detailcardpara {
            color: var(--Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            opacity: 0.6;
            line-height: 120%;
          }
        }
      }

      .detailmainheading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 120px;

        .detailmain {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 10px;

          .detailmainpara {
            color: var(--Black, #000);
            opacity: 0.6;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
          }

          .detailmainhead {
            color: var(--Black, #000);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
    }

    .detailbtns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .whitedetailbtn {
        border-radius: 15px;
        border: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        display: flex;
        padding: 13px 26px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--Black, #000);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
      }

      .leavebtn {
        border-radius: 15px;
        background: rgba(254, 0, 0, 0.1);
        display: flex;
        padding: 14px 25px;
        justify-content: center;
        align-items: center;
        color: #fc4f52;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: uppercase;
        border: none;
      }
    }
  }

  .upperdashboardmain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;

    .dashboardmainhead {
      color: #000;
      font-family: "f37";
      font-size: 22px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.44px;
      text-transform: uppercase;
    }
  }

  .dashboardboxesmain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    margin-bottom: 20px;

    .innerdashboardbox {
      border-radius: 4px;
      background: #ecf5f9;
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;

      .innerdashboardboxupper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .dashboardboxtexts {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 6px;

          .dashboardparatopbox {
            color: var(--Black, #77868b);
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            text-transform: capitalize;
          }

          .codepara {
            color: #329879;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
          }

          .dashboardheadbox {
            color: var(--Black, #021c24);
            font-size: 18px;
            font-style: normal;
            font-family: "f37";
            line-height: 120%;
            letter-spacing: -0.18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .redtext {
              color: #fc4f52;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.14px;
            }

            .greentext {
              color: #329879;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.14px;
            }
          }
        }
      }

      .valuemaindiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        position: relative;
        width: 100%;

        .dropdown {
          position: absolute;
          right: 0;
          bottom: 0;

          .btn {
            padding: 0px;
            margin: 0px;
            background: transparent;
            border: none;
            outline: none;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            padding: 0px;
            margin: 0px;
            border-radius: 4px;
            background: #ecf5f9;
            width: 208px;

            .teamsdrops {
              .innerteamdrop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 18px;
                border-bottom: 1px solid #797977;

                .teamdroppara {
                  color: #77868b;
                  font-size: 14px;
                  font-style: normal;
                  font-family: "hregular";
                  line-height: normal;
                }

                .teamdrophead {
                  color: var(--Black, #02171d);
                  font-size: 14px;
                  font-style: normal;
                  font-family: "f37";
                  line-height: normal;
                }
              }
            }
          }
        }

        .valuemain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .valueline {
            border-radius: 4px;
            background: #c1cbce;
            width: 3px;
            height: 14px;
            display: block;
          }

          .valuepara {
            color: var(--Black, #021c24);
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            letter-spacing: -0.14px;
            text-transform: uppercase;

            .darktext {
              color: var(--Black, #021c24);
              font-size: 14px;
              font-style: normal;
              font-family: "hmedium";
              line-height: 120%;
              letter-spacing: -0.14px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .ongoingprojectupper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .ongoinghead {
      color: #000;
      font-family: "f37";
      font-size: 22px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.44px;
      text-transform: uppercase;
    }

    .seeallbtn {
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      background: #fff;
      box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
      color: var(--Black, #000);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      text-transform: uppercase;
      display: flex;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .ongoingcardmain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;

    .ongoingcard {
      border-radius: 4px;
      background: #ecf5f9;
      padding: 20px;

      &:hover {
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
      }

      .ongoingcardupper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 19px;
        margin-bottom: 19px;

        .ongoincardimg {
          border-radius: 140px;
          border: 1px solid #e6e6e6;
          display: flex;
          width: 55px;
          height: 55px;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          object-position: center;

          .ongoingcardinnerimg {
            border-radius: 140px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .ongoingcardtexts {
          .ongoingcardhead {
            color: var(--V6-Dark-Theme-Box-Background-Color, #021c24);
            font-family: "f37";
            font-size: 18px;
            font-style: normal;
            line-height: 120%;
            letter-spacing: -0.18px;
            margin-bottom: 4px;
          }

          .ongoingcardpara {
            color: var(--Black, #77868b);
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
          }
        }
      }

      .ongoingcardlower {
        display: flex;
        gap: 8px;
        flex-direction: column;

        .ongoingcardlowertexts {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .cardlowertextspara {
            color: var(--Black, #77868b);
            font-size: 12px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            text-transform: uppercase;
          }

          .cardlowertextshead {
            color: var(--Black, #02171d);
            font-size: 14px;
            font-style: normal;
            font-family: "f37";
            line-height: 120%;
            display: flex;
            justify-content: flex-end;
            gap: 6px;
            align-items: center;
          }
        }
      }
    }
  }

  .tabsmain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .searchinputmain {
      position: relative;

      .searchinput {
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        width: 427px;
        padding: 15px 20px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        outline: none;

        &::placeholder {
          color: #666;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }

      .searchinputimg {
        position: absolute;
        top: 15px;
        right: 20px;
      }
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      border-radius: 4px !important;
      background: var(--Tab-Active-Color, #c1ece3) !important;
      color: #329879 !important;
    }

    .nav {
      border-radius: 4px;
      background: var(--Tab-Background, #d9e2e5);
      width: 365px;
      padding: 5px;

      .nav-item {
        // width: 50%;
        flex: 1;

        .nav-link {
          color: #77868b;
          font-family: "f37";
          font-size: 14px;
          font-style: normal;
          line-height: 100%;
          text-transform: uppercase;
          display: flex;
          padding: 12px 10px;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 4px;
          background: var(--Tab-Background, #d9e2e5);
        }
      }
    }
  }
}

.maindashboard-newstyle {
  .upperdashboardmain {
    width: 100%;
    margin-bottom: 0;
  }

  .dashboardboxesmain {
    width: 100%;
  }

  .dashboardboxesmain {
    margin-bottom: 0;
  }

  .dashboardboxesmain .innerdashboardbox {
    flex-direction: row;
    position: relative;
  }

  .dashboardboxesmain .innerdashboardbox .valuemaindiv .dropdown {
    bottom: unset;
    top: 20px;
    right: 20px;
  }

  .dashboardboxesmain .innerdashboardbox .valuemaindiv {
    position: static;
  }

  .dashboardboxesmain
    .innerdashboardbox
    .innerdashboardboxupper
    .dashboardboxtexts {
    flex-shrink: 0;
  }
}

@media (max-width: 992px) {
  .maindashboard .dashboardboxesmain {
    grid-template-columns: 1fr 1fr;
  }

  .maindashboard .ongoingcardmain {
    grid-template-columns: 1fr 1fr;
  }

  .maindashboard .dashboarddetailmain {
    flex-direction: column;
    gap: 20px;
  }

  .maindashboard .tabsmain {
    flex-direction: column;
    gap: 20px;
  }

  .maindashboard .tabsmain .searchinputmain .searchinput {
    width: 100%;
  }

  .maindashboard .tabsmain .searchinputmain {
    width: 100%;
  }

  .maindashboard .tabsmain .nav {
    width: 100%;
  }

  .maindashboard-newstyle .twice-new-style {
    flex-direction: column-reverse;
    height: auto;
  }

  .maindashboard-newstyle .upperdashboardmain {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .maindashboard .dashboardboxesmain {
    grid-template-columns: 1fr;
  }

  .maindashboard .ongoingcardmain {
    grid-template-columns: 1fr;
  }

  .maindashboard .dashboarddetailmain .dashboarddetailleft {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }

  .maindashboard .dashboarddetailmain .dashboarddetailleft .detailcard {
    margin-right: 0px;
    padding-right: 0px;
    border: none;
  }

  .maindashboard .dashboarddetailmain .dashboarddetailleft .detailmainheading {
    flex-direction: row;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.leavemodal {
  .modal-dialog {
    .modal-content {
      border-radius: 12px;
      background: #fff;
      padding: 23px 25px;

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        border: none;
        margin-bottom: 30px;

        .modal-title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }

        .btn-close {
          background: url(../../../assets/closemodal.svg);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 24px;
          box-shadow: none !important;
          outline: none !important;
          padding: 0px;
          margin: 0px;
          height: 24px;
          opacity: unset;
        }
      }

      .modal-body {
        padding: 0px;

        .leavemodalinner {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .modalimg {
            margin: 0 auto 18px;
            width: 110px;
            height: 110px;
          }

          .leavemodalpara {
            color: var(--Black, #000);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            margin-bottom: 30px;
          }
        }

        .leavemodalbtns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;

          .lightbtn {
            border-radius: 15px;
            background: #f8f8f8;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
            display: flex;
            padding: 17px 26px;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: var(--Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            text-transform: uppercase;
            border: none;
          }

          .redbtn {
            border-radius: 15px;
            background: #fc4f52;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
            display: flex;
            padding: 17px 25px;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            text-transform: uppercase;
            width: 100%;
            border: none;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .maindashboard .upperdashboardmain {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .maindashboard .dashboardboxesmain .innerdashboardbox .valuemaindiv {
    gap: 12px;
    flex-wrap: wrap;
  }

  .maindashboard .twice-elem {
    flex-direction: column;
  }

  .maindashboard .twice-elem .set-custom-common-width {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  .maindashboard .twice-elem .staking-box {
    margin-bottom: 25px;
  }
}

#smooth-tooltip {
  // transform: translate3d(1100px, 151.5px, 0px) !important;

  &.show {
    opacity: 1 !important;
  }

  .tooltip-inner {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: #02161c;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.1);
    padding: 17px;
    max-width: 303px;

    h4 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: "f37";
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 5px;
      text-align: start;
    }

    h5 {
      color: #2fbf93;
      font-size: 12px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 120%;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      text-align: start;
    }

    .twice-fields {
      margin-top: 5px;

      .inside-text {
        border-radius: 4px;
        background: #052028;
        padding: 16px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 7px;

        p {
          color: #77868b;
          font-size: 12px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          letter-spacing: 0.24px;
        }

        h6 {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
          letter-spacing: -0.12px;
        }
      }
    }
  }
}

.reputaion-modal-content {
  border-radius: 12px;
  border: 1px solid #242424;
  background: var(--Black, #000);
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.1);
  padding: 17px;

  .para {
    color: #cecece;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .inner-box {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.07);
    padding: 10px;

    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        color: #b0b0b0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0.24px;
        text-transform: uppercase;
      }

      h6 {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.12px;
        text-transform: uppercase;
      }
    }
  }

  .btn-leader {
    border-radius: 12px;
    border: 1px solid #313131;
    background: #329879;
    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.1) inset;
    color: var(--Black, #000);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    width: 100%;
    padding: 12px 25px;
    display: block;
    margin-top: 25px;
  }
}

.creditmodal {
  .modal-dialog {
    max-width: 930px !important;

    .modal-body {
      .maincredits {
        background: url("../../../assets/bgmodal.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 12px;
        background-color: #000;
        padding: 20px;

        .creditrankmain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 25px;

          .credithead {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            text-transform: uppercase;
            margin-bottom: 9px;
          }

          .creditrank {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;

            .creditrankpara {
              //   color: #82de35;
              text-align: center;
              font-size: 28px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: 0.56px;
              text-transform: uppercase;

              &.noPack {
                color: #9b9b9b;
              }

              &.emerald {
                color: #79ca29;
              }

              &.sapphire {
                color: #5263ff;
              }

              &.reddiamond {
                color: #ca2d2d;
              }

              &.diamond {
                color: #c3c0c0;
              }

              &.ruby {
                color: rgb(147, 15, 15);
              }
            }
          }

          .availablemain {
            border-radius: 10px;
            border: 1px solid #212121;
            background: rgba(255, 255, 255, 0.04);
            max-width: 260px;
            width: 100%;
            padding: 20px;

            .availablehead {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: 0.1px;
              margin-bottom: 8px;
            }

            .availablepara {
              color: #b6b6b6;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              letter-spacing: 0.1px;
              margin-bottom: 11px;

              .greynumber {
                color: #717171;
              }
            }

            .progress {
              background: rgba(55, 55, 55, 0.2);
              height: 11px;
              border-radius: 15px;

              .progress-bar {
                background-color: #329879;
                border-radius: 15px;
              }
            }
          }
        }

        .creditpackmain {
          border-radius: 12px;
          border: 1px solid #212121;
          display: flex;

          .packfirst {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 219px;

            .packhead {
              height: 116px;
              padding: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              border-bottom: 1px solid #212121;
              background: rgba(44, 44, 44, 0.2);
              width: 100%;

              .packpara {
                color: #8e8e8e;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }

            .tokenhead {
              height: 88px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              border-bottom: 1px solid #212121;
              background: rgba(44, 44, 44, 0.2);
              width: 100%;
              padding: 16px;

              .tokenpara {
                color: #8e8e8e;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }

            .purchasehead {
              height: 88px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              background: rgba(44, 44, 44, 0.2);
              width: 100%;
              padding: 16px;

              .purchasepara {
                color: #8e8e8e;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }
          }

          .activebg {
            background: linear-gradient(
              0deg,
              rgba(83, 255, 234, 0.16) 0%,
              rgba(83, 255, 234, 0) 100%
            ) !important;
          }

          .rankmain {
            width: 126px;
            background: rgba(44, 44, 44, 0.2);

            .ranktop {
              display: flex;
              height: 116px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-bottom: 1px solid #212121;
              border-left: 1px solid #212121;
              gap: 24px;
              width: 100%;

              .ranktoppara {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }

            .rankmid {
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom: 1px solid #212121;
              border-left: 1px solid #212121;
              height: 88px;
              width: 100%;

              .rankmidnumber {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }

            .rankbottom {
              display: flex;
              justify-content: center;
              align-items: center;
              border-left: 1px solid #212121;
              height: 88px;
              width: 100%;

              .rankbottompara {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: 0.28px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }
          }
        }

        .requiremainpara {
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.36px;
          text-transform: uppercase;
          margin-top: 22px;
          margin-bottom: 10px;
        }

        .rquiremain {
          border-radius: 12px;
          border: 1px solid #212121;
          background: rgba(44, 44, 44, 0.2);
          backdrop-filter: blur(4.5px);
          padding: 7px 20px;
          margin-bottom: 25px;

          .requireinner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #212121;
            padding: 13px 0px;

            .requiregreypara {
              color: #b0b0b0;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: 0.28px;
              text-transform: uppercase;
            }

            .requirenumber {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -0.16px;
              text-transform: uppercase;
            }

            .totalpara {
              color: #b0b0b0;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              letter-spacing: 0.32px;
              text-transform: uppercase;
            }

            .bluish {
              color: #329879;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.2px;
              text-transform: uppercase;
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }

        .morebottompara {
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.32px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .lastbtn {
          border-radius: 12px;
          border: 1px solid #313131;
          background: #329879;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.1) inset;
          display: flex;
          max-width: 357px;
          width: 100%;
          margin: 0 auto;
          height: 47px;
          padding: 15px 25px;
          justify-content: center;
          align-items: center;
          color: #000;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.28px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .creditmodal .modal-dialog .modal-body .maincredits .creditpackmain {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .creditmodal
    .modal-dialog
    .modal-body
    .maincredits
    .creditpackmain
    .rankmain
    .ranktop {
    padding: 16px;
  }

  .creditmodal
    .modal-dialog
    .modal-body
    .maincredits
    .creditpackmain
    .rankmain
    .rankmid {
    padding: 16px;
  }

  .creditmodal
    .modal-dialog
    .modal-body
    .maincredits
    .creditpackmain
    .rankmain
    .rankbottom {
    padding: 16px;
  }

  .creditmodal .modal-dialog .modal-body .maincredits .creditrankmain {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .creditmodal
    .modal-dialog
    .modal-body
    .maincredits
    .creditrankmain
    .availablemain {
    width: 100%;
    max-width: 100%;
  }

  .creditmodal
    .modal-dialog
    .modal-body
    .maincredits
    .rquiremain
    .requireinner
    .requiregreypara {
    font-size: 12px;
  }

  .creditmodal
    .modal-dialog
    .modal-body
    .maincredits
    .rquiremain
    .requireinner
    .requirenumber {
    font-size: 14px;
  }
}

.maindashboard-newstyle
  .dashboardboxesmain
  .innerdashboardbox
  .innerdashboardboxupper
  .dashboardboxtexts {
  flex-shrink: unset !important;
}

.maindashboard-newstyle
  .dashboardboxesmain
  .innerdashboardbox
  .innerdashboardboxupper
  .dashboardboxtexts {
  min-width: 150px;
  max-width: 150px;
}

.maindashboard-newstyle
  .dashboardboxesmain
  .innerdashboardbox
  .valuemaindiv
  .dropdown {
  position: relative !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
}

.maindashboard-newstyle .dashboardboxesmain .innerdashboardbox .valuemaindiv {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  width: unset !important;
}

@media (max-width: 600px) {
  .maindashboard-newstyle .twice-new-style .staking-box {
    max-width: 100%;
    width: 100%;
  }
  .maindashboard-newstyle .twice-new-style {
    flex-direction: column;
    gap: 25px;
  }
  .maindashboard-newstyle .upperdashboardmain {
    align-items: flex-start;
  }
  .maindashboard-newstyle .dashboardboxesmain {
    grid-template-columns: 1fr 1fr;
  }
  .maindashboard-newstyle .dashboardboxesmain .innerdashboardbox {
    padding: 15px;
  }
  .maindashboard-newstyle .dashboardboxesmain .innerdashboardbox .valuemaindiv {
    right: 10px !important;
    top: 10px !important;
  }
  .maindashboard-newstyle
    .dashboardboxesmain
    .innerdashboardbox
    .innerdashboardboxupper
    .dashboardboxtexts {
    min-width: unset !important;
    max-width: unset !important;
  }
  .maindashboard-newstyle
    .dashboardboxesmain
    .innerdashboardbox
    .innerdashboardboxupper
    img {
    display: none;
  }
  .maindashboard .tabsmain .nav .nav-item .nav-link {
    font-size: 12px;
  }
}
