.datatable {
  margin-top: 0px;

  .main-heading {
    margin: 30px 0;

    h6 {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.44px;
    }
  }

  .bgtable {
    border-radius: 4px;
    background: var(--Box-Background-Color, #ECF5F9);
    border: none;
    overflow: hidden;

    .table-responsive {
      border-radius: 0px;
      border: none;
      background-color: transparent;

      &::-webkit-scrollbar {
        display: none;
      }

      .table {
        margin: 0;
      }

      th {
        color: var(--Secondary-Text-Color, #77868B);
        font-family: "f37";
        font-size: 12px;
        font-style: normal;
        line-height: 120%; 
        letter-spacing: 0.24px;
        vertical-align: middle;
        border: none;
        height: 48px;
        padding: 10px 18px;
        position: relative;
        white-space: nowrap;
        background-color: transparent;
        cursor: pointer;
        border-bottom: 1px solid var(--Stroke, #C0CBCF);
        background: var(--Box-Background-Color, #ECF5F9);
      }

      td {
        color: var(--Primary-Text-Color, #02171D);
        font-family: "hregular";
        font-size: 12px;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.24px;
        vertical-align: middle;
        border-bottom: 1px solid var(--Stroke, #C0CBCF);
        background: var(--Box-Background-Color, #ECF5F9);
        height: 54px;
        padding: 10px 18px;
        white-space: nowrap;

        .viewmain {
          display: flex;
          align-items: center;
          gap: 4px;
          .viewpara {
            color: var(--Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.1px;
          }
        }

        .codepara {
          color: var(--Black, #000);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.28px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
        }

        .ipara {
          color: var(--Black, #000);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.28px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
        }

        .leadermaindata {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .leadermainimg {
            border-radius: 30px;
            // background: #329879;
            display: flex;
            width: 44px;
            height: 44px;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            object-position: center;

            .leaderinnerimg {
              border-radius: 30px;
              // background: #329879;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .leadertext {
            .leadermainhead {
              color: var(--Black, #000);
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              margin-bottom: 4px;
              letter-spacing: 0.24px;
            }

            .leadermainpara {
              color: #329879;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              letter-spacing: 0.24px;
            }
          }
        }

        .twice-text {
          h6 {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            /* 175% */
            letter-spacing: 0.24px;
          }

          p {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            /* 175% */
            letter-spacing: 0.24px;
            margin-top: 2px;
          }
        }

        .forend {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .common-btn {
            padding: 13px 25px;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
          }
        }
      }
    }

    .footer-content {
      border-radius: 0px 0px 4px 4px;
      background: var(--Box-Background-Color, #ECF5F9);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 22px;

      .left-f {
        h6 {
          color: var(--Secondary-Text-Color, #77868B);
          font-family: "hmedium";
          font-size: 12px;
          font-style: normal;
          line-height: 110%; 
          letter-spacing: 0.1px;
        }
      }

      .right-f {
        .pagination {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .page-link:focus {
          box-shadow: none;
          background-color: unset;
        }

        .page-link:hover {
          box-shadow: none;
          background-color: unset;
        }

        .page-link {
          color: #869397;
          font-family: "f37";
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          border: none;
          outline: none;
          box-shadow: none;
          background: transparent;
          padding: 6px 11px;

          &.active {
            background: #329879;
            border-radius: 5px;
            background: #C1EBE2;
          }
        }
      }
    }
  }

  .more-btn {
    .dropdown-toggle {
      border: none;
      background-color: transparent;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #e6e6e6;
      background: #fff;
      box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
      padding: 0px;
      margin: 0px;
      max-height: 200px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }

      .dropdown-item {
        padding: 15px 15px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        overflow: hidden;
        border-bottom: 1px solid #e6e6e6 !important;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}

.filter-btn {
  display: flex;
  flex-direction: column;
  width: 11px;
  margin-left: 10px;
}

.parent-tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 650px) {
  .accmblview {
    display: block !important;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    background: #fff;

    .main-twice-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 28px;
      padding-right: 61px;

      .accmblviewhead {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.28px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .arrowsacc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .accordion {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      border-radius: none !important;

      .accordion-button::after {
        // background: url("../../../../assets/accarrowdown.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 8.571px;
        height: 5.143px;
        position: absolute;
        top: 28px;
        right: 25px;
      }

      .accordion-item {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: none !important;

        .accordion-header {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;

          .accordion-button {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            background: var(--login-bg);
            padding: 22px 25px;
            padding-right: 61px;

            &:focus {
              box-shadow: none;
            }

            .accheadermain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 30px;
              width: 100%;

              .accmainimage {
                width: 30px;
                height: 30px;
                border-radius: 60px;
                background: rgba(0, 0, 0, 0.04);
                object-fit: cover;
                object-position: center;
                display: flex;
                align-items: center;

                .accinnerimg {
                  width: 100%;
                  height: 100%;
                  border-radius: 60px;
                  background: rgba(0, 0, 0, 0.04);
                  object-fit: cover;
                  object-position: center;
                }
              }

              .acctext {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
              }
            }
          }
        }

        .accordion-body {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;
          padding: 0px 25px;

          .acctexts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;
            border-bottom: 1px solid #1c1c1c;

            &:last-child {
              margin-bottom: 0px;
            }

            .textleft {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: 0.28px;
              text-transform: uppercase;
            }

            .textright {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 21px;
              letter-spacing: 0.28px;
            }

            .brdrbtn {
              border: 1px solid #fff;
              background: #000;
              box-shadow: 2px 2px 0px 0px #fff;
              display: flex;
              padding: 7px 15px;
              justify-content: center;
              align-items: center;
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .twice-text {
      h6 {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
      }

      p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
        margin-top: 2px;
      }
    }
  }

  .accmblview .accordion .accordion-item .accordion-header .accordion-button {
    box-shadow: none !important;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    // border-top: 1px solid #797977;
    background-color: transparent;

    .left-f {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }

    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: #a2a4a6;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 15px */
        background-color: transparent;

        &.active {
          border-radius: 5px;
          background: #000;
          box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.2) inset;
          color: #fff;
        }
      }

      .page-item:first-child .page-link {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }
  }

  .dashboardetail .auctiondetailhead {
    font-size: 20px;
  }
}

.set-tooltip-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.set-inner-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-tooltip {
  position: absolute;
  bottom: -200px;
  right: 0;
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
  width: 200px;
  z-index: 99999;
  overflow: hidden;
  .twice-elem {
    border-bottom: 1px solid #797977;
    background: #fff;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: #777;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h6 {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

tr:last-child {
  .parent-tooltip {
    bottom: 20px !important;
    // top: -120px !important;
  }
}
