.all-leaders {
  padding-top: 43px;
  padding-bottom: 43px;
  min-height: 100vh;

  .upper-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .right-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      max-width: 555px;
      width: 100%;

      .option-field {
        position: relative;
        max-width: 427px;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1.5px solid #D0DEE4;
        background: var(--Text-Field-Background, #D1DBDF);

        .inputdrop {
          background: transparent;
          background-color: transparent;

          .btn {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-family: "hregular";
            line-height: 100%;
            background: transparent;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border: none;
            height: 49px;
            border-right: 1px solid #e6e6e6;
            border-radius: 0px;

            &::after {
              display: none !important;
            }
          }

          .dropdown-menu {
            border-radius: 4px;
            border: 1.5px solid #D0DEE4;
            background: var(--Text-Field-Background, #D1DBDF);
            padding: 0px;
            margin: 0px;
            max-height: 200px;
            overflow: auto;

            &::-webkit-scrollbar {
              display: none !important;
            }

            .drop {
              padding: 10px 15px;
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-family: "hregular";
              line-height: 100%;
              overflow: hidden;
              border-bottom: 1px solid #e6e6e6;

              &:last-child {
                border-bottom: none;
              }

            }
          }
        }

        input {
          border: none;
          background: transparent;
          outline: none;
          padding: 15px 20px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;

          &::placeholder {
            color: #77868B;
          }
        }

        .search-icon {
          position: absolute;
          top: 13px;
          right: 15px;
        }
      }

      .filter-btnnn {
        border-radius: 4px;
        background: var(--Text-Field-Background, #D1DBDF);
        border: none;
        padding: 17.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: 100%;
      }
    }
  }

  .bottom-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    margin-top: 25px;

    .inner-single {
      p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 13px;
      }

      .multi-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
      }

      h6 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: #000;
        padding: 15px 10px;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        height: 30px;
      }
    }
  }
}

@media (max-width: 600px) {
  .all-leaders .upper-heading {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .all-leaders .upper-heading .right-content{
    flex-direction: column;
    gap: 10px;
  }
  .all-leaders .upper-heading .right-content .filter-btnnn{
    width: 100%;
  }
}

.set-custom-styling-leaders {
  padding: 70px 0 !important;
}
